<script setup>
    import { useI18n } from 'vue-i18n';
    import { MaEmpty, MaLinkButton } from '@mobileaction/action-kit';
    import { useGlobal } from '@/composables';
    import MaUpdateChecker from '@/components/MaUpdateChecker/index.vue';

    const { t } = useI18n();
    const { $maIntercom } = useGlobal();
</script>

<template>
    <div class="flex flex-col h-full">
        <div class="flex px-[62px] h-[72px]">
            <img
                src="@/assets/sa-logo-dark.svg"
                class="w-[150px]"
                alt="Search Ads Logo"
            >
        </div>
        <div class="h-full flex justify-center pt-10">
            <ma-empty>
                <template #icon>
                    <img src="/img/astronout.svg" alt="astronout">
                </template>
                <template #title>
                    <div class="text-gray-900 text-[28px] font-[500] mt-1 mb-4">
                        {{ t('common.wellBeBackSoon') }}
                    </div>
                </template>
                <template #description>
                    <div class="text-center ma-body-4-regular text-gray-500 max-w-[400px] mt-1">
                        {{ t('common.sorryForInconvenience1') }}
                        <ma-link-button color="blue" variant="ghost" @click="$maIntercom.show()">
                            {{ t('common.contactUsTerm1')?.toLowerCase() }}
                        </ma-link-button>
                        {{ t('common.sorryForInconvenience2') }}
                        <br>
                        <br>
                        <br>
                        <span class="ma-caption-1-regular">
                            {{ t('common.sorryForInconvenience3') }}
                        </span>
                    </div>
                </template>
            </ma-empty>
        </div>
        <ma-update-checker/>
    </div>
</template>
