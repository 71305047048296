<template>
    <ma-tooltip type="primary">
        <template #title>
            <span v-html="refreshTooltipText"/>
        </template>
        <ma-link-button
            v-if="!!currentTime"
            icon="refresh-2"
            color="dark"
            :disabled="disabled"
            @click="refreshData"
        />
    </ma-tooltip>
</template>
<script setup>
    import { isProduction } from '@/common/Config/index';
    const { $maLocalStorage, $log, $segment, $message } = useGlobal();
    import { MaLinkButton, MaNotification, MaTooltip } from '@mobileaction/action-kit';
    import { callLatestDataEvent } from '@/plugins/segment/eventDefinitions';
    import { validIntegrationOrgIdList } from '@/common/FilteringUtils';
    import { useReportController } from '@/controllers/index';
    import { useGlobal } from '@/composables/index';
    import { computed, onMounted, ref, watch } from 'vue';
    import { useI18n } from 'vue-i18n';


    import {
        isMissingAccessSearchads,
        lessThanThreeHourAgo,
        READABLE_DATETIME_FORMAT,
    } from '@/common/MaUtils.mjs';
    import messages from '@/components/Topbar/Topbar.i18n';
    import dayjs from '@/plugins/dayjs.mjs';
    import { useAccountStore } from '@/stores/Account';
    import { useAuthStore } from '@/stores/Auth';
    import { useSetupGuideStore } from '@/stores/SetupGuide';
    import { storeToRefs } from 'pinia';



    const filtersController = useReportController();
    const { t } = useI18n();

    defineOptions({
        i18n: { messages },
    });

    const lastUpdateTime = ref(null);
    const currentTime = ref(null);
    const endsTime = ref(null);
    const isNotificationActive = ref(false);
    const progressTimeInSeconds = 300;

    const accountStore = useAccountStore();
    const { integrations } = storeToRefs(accountStore);

    const authStore = useAuthStore();
    const { jwtData } = storeToRefs(authStore);

    const setupStore = useSetupGuideStore();

    const timeLeft = ref(0);

    const calcTimeLeft = () =>  {
        if (!endsTime.value) {
            return;
        }
        timeLeft.value = endsTime.value.diff(dayjs.utc(Date.now()), 'seconds');
    };
    const timer =  ref(setInterval(calcTimeLeft, 1000));

    const disabled = ref(false);

    const checkLatestData = () => {
        if (!isProduction) {
            return;
        }
        if (notRefreshableFor3Hours.value) {
            $log.warn('We refresh the data during three-hour periods');
            return;
        }
        refreshData();
    };

    const refreshData = async () => {
        disabled.value = true;
        endsTime.value = dayjs.utc(Date.now()).add(progressTimeInSeconds, 'seconds');
        $segment.track(...callLatestDataEvent.toSegment());
        try {
            await filtersController.callLatestData(validIntegrationOrgIdList() ?? []);
            lastUpdateTime.value = Date.now();
            let lut = $maLocalStorage.lastUpdateTime || {};
            lut[getSafeAccountId.value] = lastUpdateTime.value;
            $maLocalStorage.lastUpdateTime = lut;
            showSuccessNotification();
            setLastUpdateTime(lastUpdateTime.value);
            await setupStore.fetchOnboardingStatus();
        } catch (e) {
            $log.error('Failed to get latest data:', e);
        }
    };


    const checkTimer =  () =>  {
        if (!lastUpdateTimeFromSession.value) {
            return;
        }
        let lastUpdateTime = dayjs.utc(lastUpdateTimeFromSession.value);
        let diff = dayjs.utc(Date.now()).diff(lastUpdateTime, 'seconds');
        if (diff > -1 && diff < progressTimeInSeconds) {
            disabled.value = true;
            endsTime.value = dayjs.utc(Date.now()).add(progressTimeInSeconds - diff, 'seconds');
        }
    };

    const setLastUpdateTime = (updateTime) => {
        currentTime.value = dayjs.utc(updateTime).format(READABLE_DATETIME_FORMAT);
        const accountId = getSafeAccountId.value;
        let lut = $maLocalStorage.lastUpdateTime || {};
        if (updateTime && lastUpdateTimeFromSession.value && updateTime > lastUpdateTimeFromSession.value) {
            lut[accountId] = updateTime;
        }
        if (!lastUpdateTimeFromSession.value) {
            lut[accountId] = updateTime;
        }
        $maLocalStorage.lastUpdateTime = lut;
        lastUpdateTime.value = lut[accountId];
    };

    const callData = async () =>  {
        if (!integrations.value) {
            return;
        }
        try {
            const data = await filtersController.reportCurrentTime(validIntegrationOrgIdList() ?? []);
            setLastUpdateTime(data[0]);
            checkTimer();
            checkLatestData();
        } catch (e) {
            if (isMissingAccessSearchads(e)) {
                $message.warning({
                    duration: 10,
                    content: t('messages.warnings.youAreNotAuthorized'),
                });
            }
            $log.error('Failed to get data:', e);
        }
    };

    const getSafeAccountId = computed(()=> {
        try {
            return jwtData.value.accountId;
        } catch (e) {
            return null;
        }
    });
    const lastUpdateTimeFromSession = computed(()=> {
        let lut = $maLocalStorage.lastUpdateTime;
        return lut ? lut[getSafeAccountId.value] : null;
    });

    const formattedTime = computed(() => {
        if (!currentTime.value) {
            return;
        }
        return currentTime.value;
    });
    const refreshTooltipText = computed(()=> {
        if (!formattedTime.value) {
            return t('lastUpdateTimeNoData', { time: formattedTime.value });
        }
        return disabled.value ? t('lastUpdateTimeEnabled', { time: formattedTime.value }): t('lastUpdateTimeDisabled', { time: formattedTime.value });
    });

    const notRefreshableFor3Hours = computed(()=> {
        return lessThanThreeHourAgo(lastUpdateTime.value);
    });

    const showSuccessNotification = () => {
        if (isNotificationActive.value) {
            return;
        }
        const duration = 3000;
        MaNotification.success({
            title: t('latestDataCalled'),
            duration,
            placement: 'topRight',
            variant: 'light',
            icon: 'tick-circle-bold',
            closable: true,
        });
        isNotificationActive.value = true;
        setTimeout(() => {
            isNotificationActive.value = false;
        }, duration);
    };

    onMounted(async () => {
        await callData();
    });

    watch(endsTime, () => {
        timer.value = setInterval(calcTimeLeft, 1000);
    });

    watch(timeLeft, () => {
        if (timeLeft.value < 0) {
            clearInterval(timer.value);
            disabled.value = false;
            endsTime.value = null;
            callData();
        }
    });
    watch(integrations, async (newVal) => {
        if (newVal) {
            await callData();
        }
    });
</script>
