import { loadLinkAsync, loadScriptAsync } from '@/common/MaUtils.mjs';

(async() => {
    // attach flag-icon library
    await loadLinkAsync('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/css/flag-icon.min.css', 'head', {
        defer: true,
    });

    // load beamer script and set initial config
    await loadBeamer();
})();

async function loadBeamer() {
    await loadScriptAsync('https://app.getbeamer.com/js/beamer-embed.js', 'head', {
        defer: true,
        async: true,
    });
    window.beamer_config = {
        selector: '#ma-beamer-container',
        product_id: 'nbVTyums51472',
        top: 1,
        right: 1,
    };
}
