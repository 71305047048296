import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import dayjs from '@/plugins/dayjs.mjs';
import { getProducts } from '@/common/MaRequests/User';
import { READABLE_DATE_FORMAT } from '@/common/MaUtils.mjs';
import { globalProperties as app } from '@/main';
import { useAuthStore } from '@/stores/Auth';

export const usePlanStore = defineStore('plan', () => {
    const products = ref([]);
    const featureSet = ref([]);
    const productsFetchFlag = ref(false);
    const authStore = useAuthStore();

    const updateProducts = async (cacheInvalidate = false) => {
        try {
            const data = await getProducts(cacheInvalidate);
            app.$maSessionStorage.validFeatures = Object.keys(data.featureMap);
            products.value = data?.products || [];
            featureSet.value = Object.values(data?.featureMap || {});
            productsFetchFlag.value = true;
        } catch (e) {
            products.value = [];
            featureSet.value = [];
            productsFetchFlag.value = false;
            const { errors } = e;
            if (errors?.includes('NOT_LOGGED_IN')) {
                return;
            }
            console.error('Failed to check authorization', e);
            throw e;
        }
    };

    const isPlanFetched = computed(() => productsFetchFlag.value);

    const accessSearchAds = computed(() => {
        if (!products.value || !featureSet.value) {
            return { valid: false };
        }
        const productsFeatureSets = products.value.flatMap(product => product.featureSet);
        const feature = productsFeatureSets.find(f => f && f.type === 'ACCESS_SEARCHADS_COM');
        if (!feature) {
            return { valid: false };
        }
        const { expirationDate } = feature;
        const expDate = expirationDate ? dayjs(expirationDate) : null;
        feature.expiration = expDate ? expDate : null;
        feature.expirationFormatted = expDate ? expDate.format(READABLE_DATE_FORMAT) : null;
        return {
            feature,
            valid: !expirationDate ? true : (expDate.diff(dayjs(), 'days') > 0),
        };
    });

    const userFeatureMapping = computed(() => {
        if (!featureSet.value) {
            return {};
        }
        let features = {};
        if (featureSet.value?.length) {
            featureSet.value.filter(f => authStore.maImpFullAccess || !f.expirationDate || new Date(f.expirationDate).getTime() < Date.now())
                .forEach((f) => {
                    features[f.type] = f;
                });
        }
        return features;
    });

    const userFeatureList = computed(() => Object.keys(userFeatureMapping.value).map(k => userFeatureMapping.value[k]));

    const validFeatures = computed(() => Object.keys(userFeatureMapping.value));

    const hasAccessSearchAdsTools = computed(() => validFeatures.value.includes('ACCESS_SEARCHADS_TOOLS'));

    const hasCampaignForecastAccess = computed(() => validFeatures.value.includes('SA_CAMPAIGN_FORECAST'));

    const hasBudgetAllocationAccess = computed(() => validFeatures.value.includes('SA_BUDGET_ALLOCATION'));

    const hasQuickAutomationsAccess = computed(() => validFeatures.value.includes('SA_QUICK_AUTOMATIONS'));

    const hasKeywordAuctionInsightsAccess = computed(() => validFeatures.value.includes('SA_KEYWORD_AUCTION_INSIGHTS'));

    const hasAdsManagerAccess = computed(() => validFeatures.value.includes('SA_ADS_MANAGER'));

    const hasAccessLTVUpload = computed(() => validFeatures.value.includes('SA_LTV_UPLOAD'));

    const isSmartAdmin = computed(() => validFeatures.value.includes('SEARCHADS_SMART_ADMIN'));

    const hasKeywordAdvisorAccess = computed(() => validFeatures.value.includes('SA_KEYWORD_ADVISOR'));

    const isSearchAdsFreeUser = computed(() => products.value.map(p => p.name).includes('SA_FREEMIUM'));

    const isSearchAdsUser = computed(() => products.value.map(p => p.name).includes('SEARCH_ADS'));

    const accountHasProduct = computed(() => products.value.length > 0);

    const hasSearchAdsTrialLast7Days = computed(() => {
        const searchAds = products.value.find(p => p && p.name === 'SEARCH_ADS') || {};
        const expDate = searchAds.expirationDate ? dayjs(searchAds.expirationDate) : null;
        return expDate ? (expDate.diff(dayjs(), 'days') < 7) : false;
    });

    const hasSearchAdsTrial = computed(() => {
        const searchAds = products.value.find(p => p && p.name === 'SEARCH_ADS') || {};
        const expDate = searchAds.expirationDate ? dayjs(searchAds.expirationDate) : null;
        return (expDate && expDate.diff(dayjs(), 'days') >= 0) && searchAds.planType === 'TRIAL';
    });

    const trialRemainingDays = computed(() => {
        const searchAds = products.value.find(p => p && p.name === 'SEARCH_ADS') || {};
        const expDate = searchAds.expirationDate ? dayjs(searchAds.expirationDate) : null;
        return expDate ? expDate.diff(dayjs(), 'days') : -1;
    });

    return {
        products,
        featureSet,
        productsFetchFlag,
        updateProducts,
        isPlanFetched,
        accessSearchAds,
        userFeatureMapping,
        userFeatureList,
        validFeatures,
        hasAccessSearchAdsTools,
        hasCampaignForecastAccess,
        hasBudgetAllocationAccess,
        hasQuickAutomationsAccess,
        hasKeywordAuctionInsightsAccess,
        hasAdsManagerAccess,
        hasAccessLTVUpload,
        isSmartAdmin,
        hasKeywordAdvisorAccess,
        isSearchAdsFreeUser,
        isSearchAdsUser,
        accountHasProduct,
        hasSearchAdsTrialLast7Days,
        hasSearchAdsTrial,
        trialRemainingDays,
    };
});
