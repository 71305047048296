import colors from 'tailwindcss/colors';
import { INSIGHTS_DOMAIN } from '@/common/Config/index';

export const LAYOUTS = {
    DEFAULT: '', // if layout on meta is empty/undefined
    AUTHENTICATION: 'authentication',
    TOPBAR: 'topbar',
};

export default [
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('@/pages/NotFound.vue'),
        meta: { title: 'Not Found' },
    },
    {
        path: '/',
        name: 'home',
        redirect: { name: 'dashboard' },
    },
    {
        path: '/mainboard/:tab?',
        redirect: { name: 'dashboard' },
    },
    {
        path: '/beta/:tab?',
        redirect: { name: 'dashboard' },
    },
    {
        path: '/getting-start',
        redirect: { name: 'setup-guide' },
    },
    {
        path: '/optimization/grader',
        redirect: { name: 'grader' },
    },
    {
        path: '/verify/:token?',
        name: 'verify',
        component: () => import('@/pages/Verify.vue'),
        meta: {
            title: 'Verify',
            layout: LAYOUTS.AUTHENTICATION,
            disableUpdateChecker: true,
        },
    },
    {
        path: '/login',
        name: 'login',
        redirect: () => window.location.replace( INSIGHTS_DOMAIN + '/auth/login/searchads'),
        meta: { title: 'Login', layout: LAYOUTS.AUTHENTICATION },
    },
    {
        path: '/login-sso',
        name: 'login-sso',
        redirect: () => window.location.replace(INSIGHTS_DOMAIN + '/auth/login-sso/searchads'),
        meta: { title: 'Single Sign On', layout: LAYOUTS.AUTHENTICATION },
    },
    {
        path: '/auth/:authType/callback',
        name: 'callback',
        redirect: to => window.location.replace(`${INSIGHTS_DOMAIN}/auth/${to.params.authType}/callback/searchads`),
        meta: {
            title: 'Authentication Callback',
            layout: LAYOUTS.AUTHENTICATION,
            intercom: false,
            disableUpdateChecker: true,
        },
    },
    {
        path: '/register',
        name: 'register',
        redirect: () => window.location.replace(INSIGHTS_DOMAIN + '/auth/register/searchads'),
        meta: { title: 'Register', layout: LAYOUTS.AUTHENTICATION },
    },
    {
        path: '/recover',
        name: 'recover',
        redirect: () => window.location.replace(INSIGHTS_DOMAIN + '/auth/recover/searchads'),
        meta: { title: 'Recover Password', layout: LAYOUTS.AUTHENTICATION },
    },
    {
        path: '/integration',
        name: 'integration',
        component: () => import('@/pages/Integration/index.vue'),
        meta: { title: 'Integration' },
    },
    {
        path: '/and-or-poc',
        name: 'and-or-poc',
        component: () => import('@/pages/AndOr/index.vue'),
        meta: { title: 'And or POC' },
    },
    {
        path: '/slack-integration',
        name: 'slack-integration',
        component: () => import('@/pages/SlackIntegration.vue'),
        meta: { title: 'Slack Integration', intercom: false },
    },
    {
        path: '/ad-intelligence/:tab?',
        name: 'ad-intelligence',
        redirect: { name: 'keyword-auction-insights' },
    },
    {
        path: '/keyword-auction-insights',
        name: 'keyword-auction-insights',
        component: () => import('@/pages/KeywordAuctionInsights/index.vue'),
        meta: {
            title: 'Keyword Auction Insights',
            feature: 'SA_KEYWORD_AUCTION_INSIGHTS',
            className: 'bg-gray-50 !p-0',
        },
        redirect: { name: 'search-by-app' },
        children: [
            {
                path: 'app',
                name: 'search-by-app',
                components: {
                    tab: () => import('@/pages/KeywordAuctionInsights/Components/SearchByApp/SearchByApp.vue'),
                },
                meta: { title: 'Search By App' },
            },
            {
                path: 'keyword',
                name: 'search-by-keyword',
                components: {
                    tab: () => import('@/pages/KeywordAuctionInsights/Components/SearchByKeyword/SearchByKeyword.vue'),
                },
                meta: { title: 'Search By Keyword' },
            },
            {
                path: 'top-advertisers',
                name: 'top-advertisers',
                components: {
                    tab: () => import('@/pages/KeywordAuctionInsights/Components/TopAdvertisers/TopAdvertisers.vue'),
                },
                meta: { title: 'Top Advertisers' },
            },
            {
                path: 'keyword-gap',
                name: 'keyword-gap',
                components: {
                    tab: () => import('@/pages/KeywordAuctionInsights/Components/KeywordGap/KeywordGap.vue'),
                },
                meta: { title: 'Keyword Gap' },
            },
        ],
    },
    {
        path: '/dashboard/:tab?',
        name: 'dashboard',
        props: true,
        component: () => import('@/pages/Dashboard/index.vue'),
        meta: {
            title: 'Dashboard',
            feature: ['SA_ADS_MANAGER', 'SA_ADS_MANAGER_LITE'],
            className: '!p-0 bg-[#f5f5f5]',
        },
    },
    {
        path: '/dashboard-lite',
        name: 'dashboard-lite',
        redirect: { name: 'dashboard' },
    },
    {
        path: '/report',
        name: 'report',
        props: true,
        component: () => import('@/pages/Funnels/index.vue'),
        meta: {
            title: 'Reporting',
            feature: 'SA_FUNNELS',
            isMMPRelated: true,
        },
    },
    {
        path: '/add-keywords',
        name: 'add-keywords',
        component: () => import('@/pages/AddKeywordsPage/index.vue'),
        meta: {
            title: 'Add Keywords',
            layout: LAYOUTS.TOPBAR,
            layoutBgColor: 'white',
        },
        props: route => ({ addingNegativeKeywords: route.query?.negativeKeywords === 'true' ? true : false }),
    },
    {
        path: '/add-keywords/check',
        name: 'check-issues',
        component: () => import('@/pages/AddKeywordsPage/CheckIssues/index.vue'),
        meta: {
            title: 'Add Keywords',
            layout: LAYOUTS.TOPBAR,
            layoutBgColor: 'white',
        },
        props: route => ({ addingNegativeKeywords: route.query?.negativeKeywords === 'true' ? true : false }),
    },
    {
        path: '/manage/edit/campaign',
        name: 'manage/edit-campaigns',
        props: true,
        component: () => import('@/pages/Manage/Edit/Campaign/index.vue'),
        meta: { title: 'Edit Campaign Settings', intercom: false },
    },
    {
        path: '/manage/edit/ad-group',
        name: 'manage/edit-ad-groups',
        component: () => import('@/pages/Manage/Edit/AdGroup/index.vue'),
        meta: { title: 'Edit Ad Group Settings', intercom: false },
    },
    {
        path: '/automations/:tab?',
        name: 'automations',
        redirect: { name: 'automation-rules' },
    },
    {
        path: '/automation',
        name: 'automation',
        component: () => import('@/pages/Automations/index.vue'),
        meta: {
            title: 'Automation Rules',
            feature: 'SA_AUTOMATIONS',
        },
        children: [
            {
                path: '/automation/rules',
                name: 'automation-rules',
                component: () => import('@/pages/Automations/Components/RuleList.vue'),
                meta: { title: 'Automation Rules' },
            },
            {
                path: '/automation/logs',
                name: 'automation-logs',
                component: () => import('@/pages/Automations/Components/RuleLogs.vue'),
                meta: { title: 'Automation Logs' },
            },
        ],
    },
    {
        path: '/automation-creation/:ruleId?',
        name: 'automations-creation',
        component: () => import('@/pages/Automations/Creation/index.vue'),
        meta: {
            title: 'Automation Creation',
            feature: 'SA_AUTOMATIONS',
            layout: LAYOUTS.TOPBAR,
            layoutBgColor: colors.white,
            layoutRemoveFixedWidth: true,
        },
    },
    {
        path: '/automation-templates/:id?',
        name: 'automation-templates',
        component: () => import('@/pages/Automations/Templates/index.vue'),
        meta: {
            title: 'Automation Templates',
            feature: 'SA_AUTOMATIONS',
            layout: LAYOUTS.TOPBAR,
            layoutBgColor: colors.white,
        },
    },
    {
        path: '/automation-activites/:id',
        name: 'automation-activities',
        component: () => import('@/pages/Automations/LogDetails/index.vue'),
        meta: {
            title: 'Automation Log Details',
            feature: 'SA_AUTOMATIONS',
            layout: LAYOUTS.DEFAULT,
            layoutBgColor: colors.white,
        },
    },
    {
        path: '/action-kit',
        name: 'action-kit',
        component: () => import('@/actionkit/index.vue'),
        meta: {
            title: 'Action Kit Showcase',
            layout: LAYOUTS.TOPBAR,
            layoutBgColor: colors.white,
        },
        children: [
            {
                path: '/action-kit/competitor-track',
                name: 'action-kit-competitor-track',
                component: () => import('@/actionkit/competitor-track/index.vue'),
                meta: {
                    title: 'Competitor Track Showcase',
                    layout: LAYOUTS.TOPBAR,
                    layoutBgColor: colors.white,
                },
            },
            {
                path: '/action-kit/app-search',
                name: 'action-kit-app-search',
                component: () => import('@/actionkit/app-search/index.vue'),
                meta: {
                    title: 'App Search Showcase',
                    layout: LAYOUTS.TOPBAR,
                    layoutBgColor: colors.white,
                },
            },
            {
                path: '/action-kit/goal-creation',
                name: 'action-kit-goal-creation',
                component: () => import('@/actionkit/goal-creation/index.vue'),
                meta: {
                    title: 'Goal Creation Showcase',
                    layout: LAYOUTS.TOPBAR,
                    layoutBgColor: colors.white,
                },
            },
        ],
    },
    // redirect old settings/exports to logs in case user keeps it in their bookmarks
    // BEGIN can be removed after Jan 2025
    {
        path: '/settings/exports',
        redirect: { name: 'exports-logs' },
    },
    {
        path: '/settings/grader-logs',
        redirect: { name: 'audit-logs' },
    },
    {
        path: '/settings/mail-logs',
        redirect: { name: 'mail-logs' },
    },
    // END can be removed after Jan 2025
    {
        path: '/settings',
        name: 'settings',
        component: () => import('@/pages/Settings/index.vue'),
        meta: {
            title: 'Settings',
            className: '!bg-gray-100 !p-0',
        },
        redirect: { name: 'account-settings' },
        children: [
            {
                path: 'account',
                name: 'account-settings',
                component: () => import('@/pages/Settings/AccountSettings.vue'),
            },
            {
                path: 'sso',
                name: 'sso-settings',
                component: () => import('@/pages/Settings/SsoSettings.vue'),
            },
            {
                path: 'mail',
                name: 'mail-settings',
                component: () => import('@/pages/Settings/MailSettings.vue'),
            },
            {
                path: 'payments',
                name: 'payment-settings',
                component: () => import('@/pages/Settings/Payment/Payment.vue'),
            },
            {
                path: 'team-members',
                name: 'team-member-settings',
                component: () => import('@/pages/Settings/TeamMemberSettings.vue'),
            },
            {
                path: 'scheduled-reports',
                name: 'scheduled-reports',
                component: () => import('@/pages/Settings/ScheduledReports.vue'),
            },
        ],
    },
    {
        path: '/invoice/:invoiceId',
        name: 'invoice',
        component: () => import('@/pages/Invoice/index.vue'),
        meta: { title: 'Invoice' },
    },
    {
        path: '/attributions',
        name: 'attributions',
        component: () => import('@/pages/Attributions/index.vue'),
        meta: {
            title: 'Attribution Partners',
            feature: 'SA_ATTRIBUTION',
        },
    },
    {
        path: '/conversions',
        name: 'conversions',
        component: () => import('@/pages/Conversions/index.vue'),
        meta: {
            title: 'Conversions',
            feature: 'SA_GOALS',
            isMMPRelated: true,
        },
    },
    {
        path: '/create-conversion/:id?',
        name: 'conversions/create',
        component: () => import('@/pages/Conversions/MaCreateConversion.vue'),
        meta: { title: 'Create Conversion', intercom: false },
    },
    {
        path: '/manage/campaign/create',
        name: 'campaign-management',
        component: () => import('@/pages/Manage/CampaignCreate.vue'),
        meta: { title: 'Create Campaign', intercom: false },
    },
    {
        path: '/manage/campaign/:campaignId/adgroup/create',
        name: 'adgroup-management',
        component: () => import('@/pages/Manage/AdGroupCreate.vue'),
        meta: { title: 'Create Ad Group' },
    },
    {
        path: '/manage/campaign/adgroup/create',
        name: 'adgroup-create',
        component: () => import('@/pages/Manage/AdGroupCreate.vue'),
        meta: { title: 'Create Ad Group' },
    },
    {
        path: '/keyword-hunt',
        name: 'keyword-hunt',
        component: () => import('@/pages/KeywordHunt.vue'),
        meta: {
            title: 'Keyword Hunt',
            feature: 'SA_ORGANIC_KEYWORD_HUNT',
            noIntegrationRequired: true,
        },
    },
    {
        path: '/setup-guide',
        name: 'setup-guide',
        component: () => import('@/pages/SetupGuide/index.vue'),
        meta: { title: 'Setup Guide' },
    },
    {
        path: '/duplicate/campaign',
        name: 'duplicate-campaign',
        props: true,
        component: () => import('@/pages/DuplicateCampaign/index.vue'),
        meta: { title: 'Duplicate Campaign' },
    },
    {
        path: '/duplicate/ad-group',
        name: 'duplicate-ad-group',
        props: true,
        component: () => import('@/pages/DuplicateAdGroup/index.vue'),
        meta: { title: 'Duplicate Ad Group' },
    },
    {
        path: '/overview',
        name: 'overview',
        component: () => import('@/pages/Overview/index.vue'),
        meta: {
            title: 'Overview',
            feature: 'SA_OVERVIEW',
        },
    },
    {
        path: '/competitors',
        name: 'competitors',
        component: () => import('@/pages/Competitors/index.vue'),
        meta: {
            title: 'Competitors',
            feature: 'SA_COMPETITORS',
        },
    },
    {
        path: '/sa-integ/auth/callback',
        name: 'sa-integ-callback',
        component: () => import('@/pages/IntegrationAuthCallback.vue'),
        meta: { title: 'Sa Integration Callback' },
    },
    {
        path: '/grader-report/:reportId',
        name: 'grader-report',
        component: () => import('@/pages/Experiments/GraderReport.vue'),
        meta: { title: 'Free Search Ads Performance Grader', layout: LAYOUTS.AUTHENTICATION },
    },
    {
        path: '/grader-report/v2/:reportId',
        name: 'grader-report-v2',
        component: () => import('@/pages/Experiments/GraderReportV2.vue'),
        meta: { title: 'Free Search Ads Performance Grader', layout: LAYOUTS.AUTHENTICATION },
    },
    {
        path: '/invite/complete',
        name: 'sa-invite-complete',
        component: () => import('@/pages/InviteComplete.vue'),
        meta: { title: 'Accept Invitation', layout: LAYOUTS.AUTHENTICATION },
    },
    {
        path: '/benchmarks',
        name: 'sa-benchmarks',
        component: () => import('@/pages/Benchmarks/index.vue'),
        meta: {
            title: 'Benchmarks',
            feature: 'SA_BENCHMARKS',
            noIntegrationRequired: true,
        },
    },
    {
        path: '/smart-bidding',
        name: 'smart-bidding',
        component: () => import('@/pages/SmartBidding/index.vue'),
        meta: {
            title: 'Smart Bidding',
            className: 'bg-gray-50 !p-0',
        },
        redirect: { name: 'cpa-optimization' },
        children: [
            {
                path: 'cpa',
                name: 'cpa-optimization',
                components: {
                    tab: () => import('@/pages/SmartBidding/components/CpaOptimization.vue'),
                },
                meta: { title: 'Smart Bidding CPA Optimization' },
            },
            {
                path: 'cpi',
                name: 'cpi-optimization',
                components: {
                    tab: () => import('@/pages/SmartBidding/components/CpiOptimization.vue'),
                },
                meta: { title: 'Smart Bidding CPI Optimization' },
            },
            {
                path: 'cpg',
                name: 'cpg-optimization',
                components: {
                    tab: () => import('@/pages/SmartBidding/components/CpgOptimization.vue'),
                },
                meta: { title: 'Smart Bidding CPG Optimization' },
            },
            {
                path: 'roas',
                name: 'roas-optimization',
                components: {
                    tab: () => import('@/pages/SmartBidding/components/RoasOptimization.vue'),
                },
                meta: { title: 'Smart Bidding ROAS Optimization' },
            },
        ],
    },
    {
        path: '/logs',
        name: 'logs',
        component: () => import('@/pages/Logs/index.vue'),
        meta: {
            title: 'Logs',
            className: '!p-0',
        },
        redirect: { name: 'ads-manager-logs' },
        children: [
            {
                path: 'ads-manager',
                name: 'ads-manager-logs',
                component: () => import('@/pages/Logs/AdsManagerLogs.vue'),
            },
            {
                path: 'exports',
                name: 'exports-logs',
                component: () => import('@/pages/Logs/ExportLogs.vue'),
            },
            {
                path: 'audit',
                name: 'audit-logs',
                component: () => import('@/pages/Logs/AuditLogs.vue'),
            },
            {
                path: 'mail',
                name: 'mail-logs',
                component: () => import('@/pages/Logs/MailLogs.vue'),
            },
            {
                path: 'scheduled-report-logs',
                name: 'scheduled-report-logs',
                component: () => import('@/pages/Logs/ScheduledReports.vue'),
            },
        ],
    },
    {
        path: '/log-activities/:id',
        name: 'bulk-edit-activities',
        meta: {
            title: 'Log details',
            className: '!p-0',
        },
        component: () => import('@/pages/Logs/BulkEditLogDetails.vue'),
    },
    {
        path: '/smart-notifications',
        name: 'smart-notifications',
        redirect: { name: 'dashboard' },
    },
    {
        path: '/campaign-forecasting',
        name: 'campaign-forecasting',
        component: () => import('@/pages/CampaignForecasting/index.vue'),
        meta: {
            title: 'Campaign Forecasting',
            feature: 'SA_CAMPAIGN_FORECAST',
        },
    },
    {
        path: '/audit',
        name: 'audit',
        component: () => import('@/pages/Grader/index.vue'),
        meta: {
            title: 'Grader',
            feature: 'SA_GRADER',
        },
        alias: '/grader/',
    },
    {
        path: '/recommendations',
        name: 'recommendations',
        component: () => import('@/pages/Recommendations/index.vue'),
        meta: {
            title: 'Recommendations',
            feature: 'SA_RECOMMENDATIONS',
        },
    },
    {
        path: '/keyword-advisor',
        name: 'keyword-advisor',
        component: () => import('@/pages/KeywordAdvisor/index.vue'),
        meta: {
            title: 'Keyword Advisor',
            feature: 'SA_KEYWORD_ADVISOR',
        },
    },
    {
        path: '/keyword-advisor-steps/',
        name: 'keyword-advisor-steps',
        redirect: { name: 'keyword-advisor-steps-app-selection' },
        component: () => import('@/pages/KeywordAdvisorSteps/index.vue'),
        meta: {
            title: 'Keyword Advisor',
            layout: LAYOUTS.TOPBAR,
        },
        children: [
            {
                path: '/keyword-advisor-steps/select-your-app',
                name: 'keyword-advisor-steps-app-selection',
                component: () => import('@/pages/KeywordAdvisorSteps/MaKeywordAdvisorSelectApp.vue'),
                meta: { title: 'App Selection - Keyword Advisor' },
            },
            {
                path: '/keyword-advisor-steps/add-your-competitor',
                name: 'keyword-advisor-competitor-selection',
                component: () => import('@/pages/KeywordAdvisorSteps/MaKeywordAdvisorSelectCompetitor.vue'),
                meta: { title: 'Competitor Selection - Keyword Advisor' },
            },
            {
                path: '/keyword-advisor-steps/select-keywords',
                name: 'keyword-advisor-keyword-selection',
                component: () => import('@/pages/KeywordAdvisorSteps/MaKeywordAdvisorSelectKeywords.vue'),
                meta: { title: 'Keywords Selection - Keyword Advisor' },
            },
        ],
    },
    {
        path: '/spotlight/',
        name: 'spotlight',
        component: () => import('@/pages/Spotlight/index.vue'),
        meta: {
            title: 'Spotlight',
        },
    },
    {
        path: '/campaign-wizard/',
        name: 'campaign-wizard',
        component: () => import('@/pages/QuickCampaignSetup/index.vue'),
        meta: {
            title: 'Quick Campaign Setup',
            feature: 'SA_QUICK_CAMPAIGN_SETUP',
        },
        alias: '/quick-campaign/',
    },
    {
        path: '/optimize/',
        name: 'optimize',
        component: () => import('@/pages/QuickAutomation/index.vue'),
        meta: {
            title: 'Quick Automations',
            feature: 'SA_QUICK_AUTOMATIONS',
        },
        alias: '/quick-automation/',
    },
    {
        path: '/quick-campaign-setup/',
        name: 'quick-campaign-setup',
        redirect: { name: 'quick-campaign-setup-app-selection' },
        component: () => import('@/pages/QuickCampaignSetupSteps/index.vue'),
        meta: {
            title: 'Quick Campaign Setup',
            feature: 'SA_QUICK_CAMPAIGN_SETUP',
            layout: LAYOUTS.TOPBAR,
        },
        children: [
            {
                path: '/quick-campaign-setup/select-your-app',
                name: 'quick-campaign-setup-app-selection',
                component: () => import('@/pages/QuickCampaignSetupSteps/MaQcsStepSelectApp.vue'),
                meta: { title: 'App Selection - Quick Campaign Setup' },
            },
            {
                path: '/quick-campaign-setup/add-your-competitor',
                name: 'quick-campaign-setup-competitor-selection',
                component: () => import('@/pages/QuickCampaignSetupSteps/MaQcsStepSelectCompetitor.vue'),
                meta: { title: 'App Selection - Quick Campaign Setup' },
            },
            {
                path: '/quick-campaign-setup/add-your-keyword-list',
                name: 'quick-campaign-setup-keyword-selection',
                component: () => import('@/pages/QuickCampaignSetupSteps/MaQcsStepSelectKeyword.vue'),
                meta: { title: 'App Selection - Quick Campaign Setup' },
            },
            {
                path: '/quick-campaign-setup/summary',
                name: 'quick-campaign-setup-summary',
                component: () => import('@/pages/QuickCampaignSetupSteps/MaQcsStepSummary.vue'),
                meta: { title: 'Summary - Quick Campaign Setup' },
            },
        ],
    },
    {
        path: '/budget-allocation/',
        name: 'budget-allocation',
        component: () => import('@/pages/BudgetAllocation/index.vue'),
        meta: {
            title: 'Budget Allocation',
            feature: 'SA_BUDGET_ALLOCATION',
        },
    },
    {
        path: '/duplicate-account/:chosenAccountId',
        name: 'duplicate-account',
        component: () => import('@/pages/DuplicateAccount/index.vue'),
        meta: { title: 'Account Duplication' },
        props: true,
    },
    {
        path: '/impersonation/',
        name: 'impersonation-auth',
        component: () => import('@/pages/ImpersonationAuth.vue'),
        meta: {
            title: 'Impersonation',
            layout: LAYOUTS.AUTHENTICATION,
            disableUpdateChecker: true,
        },
    },
];
