import { defineStore } from 'pinia';
import { reactive, ref } from 'vue';
import {
    AD_FILTER_COLUMNS,
    AD_GROUP_FILTER_COLUMNS,
    APP_FILTER_COLUMNS,
    CAMPAIGN_FILTER_COLUMNS,
    CPP_FILTER_COLUMNS,
    DASHBOARD_TABS,
    FILTER_LEVELS,
    KEYWORD_FILTER_COLUMNS,
    NEGATIVE_KEYWORD_FILTER_COLUMNS,
    SEARCH_TERM_FILTER_COLUMNS,
    CAMPAIGN_GROUP_COLUMNS,
} from '@/common/FilteringUtils';

export const REPORT_LEVEL = {
    ACCOUNT: 'ACCOUNT',
    APP: 'APP',
    CAMPAIGN: 'CAMPAIGN',
    AD_GROUP: 'AD_GROUP',
    KEYWORD: 'KEYWORD',
    NEGATIVE: 'NEGATIVE',
    SEARCH_TERM: 'SEARCH_TERM',
    AD: 'AD',
    CPP: 'CPP',
};

//We can remove this mapping once we update Dashboard/index to use REPORT_LEVEL directly
export const ACTIVE_NAME_REPORT_LEVEL_MAPPING = {
    [DASHBOARD_TABS.ACCOUNTS]: REPORT_LEVEL.ACCOUNT,
    [DASHBOARD_TABS.APPS]: REPORT_LEVEL.APP,
    [DASHBOARD_TABS.CAMPAIGNS]: REPORT_LEVEL.CAMPAIGN,
    [DASHBOARD_TABS.AD_GROUPS]: REPORT_LEVEL.AD_GROUP,
    [DASHBOARD_TABS.ADS]: REPORT_LEVEL.AD,
    [DASHBOARD_TABS.KEYWORDS]: REPORT_LEVEL.KEYWORD,
    [DASHBOARD_TABS.NEGATIVE_KEYWORDS]: REPORT_LEVEL.NEGATIVE,
    [DASHBOARD_TABS.SEARCH_TERMS]: REPORT_LEVEL.SEARCH_TERM,
    [DASHBOARD_TABS.CPP]: REPORT_LEVEL.CPP,
};


export const useAdsManagerStore = defineStore('ads-manager', () => {
    const reportLevel = ref(REPORT_LEVEL.ACCOUNT);
    const showChartAsCumulative = ref(false);
    const adsManagerChartGranularity = ref('DAILY');


    const tabFilters = ref([]);
    const preSelectedData = ref([]);

    const updatePreSelectedData = (data) => {
        preSelectedData.value = data;
    };

    const updateFilterList = (filters) => {
        tabFilters.value = filters;
    };

    const updateTab = ({ tabIndex, tabInfo }) => {
        Object.assign(tabs[tabIndex], tabInfo);
    };

    const tabs = reactive([
        {
            page: DASHBOARD_TABS.ACCOUNTS,
            detailItem: null,
            loading: { table: false, badge: false, chart: false },
            error: { table: false, badge: false, chart: false },
            tryAgain: { table: false, badge: false, chart: false },
            badgeOptions: null,
            selectedData: [],
            automationType: 'ACCOUNT',
            automationQueryType: 'ACCOUNT',
            requestLevel: FILTER_LEVELS.ACCOUNT,
            refreshSigner: true,
            refresh: false,
            filterableColumns: CAMPAIGN_GROUP_COLUMNS,
            removedFilterObject: { item: {}, remainingItems: [] },
        },
        {
            page: DASHBOARD_TABS.APPS,
            detailItem: null,
            loading: { table: false, badge: false, chart: false },
            error: { table: false, badge: false, chart: false },
            tryAgain: { table: false, badge: false, chart: false },
            badgeOptions: null,
            selectedData: [],
            automationType: 'ACCOUNT',
            automationQueryType: 'APP',
            requestLevel: FILTER_LEVELS.APP,
            refreshSigner: true,
            refresh: false,
            filterableColumns: APP_FILTER_COLUMNS,
            removedFilterObject: { item: {}, remainingItems: [] },
        },
        {
            page: DASHBOARD_TABS.CAMPAIGNS,
            detailItem: null,
            loading: { table: false, badge: false, chart: false },
            error: { table: false, badge: false, chart: false },
            tryAgain: { table: false, badge: false, chart: false },
            badgeOptions: null,
            selectedData: [],
            automationType: 'APP',
            automationQueryType: 'CAMPAIGN',
            requestLevel: FILTER_LEVELS.CAMPAIGN,
            refreshSigner: true,
            refresh: false,
            filterParam: null,
            filterableColumns: CAMPAIGN_FILTER_COLUMNS,
            removedFilterObject: { item: {}, remainingItems: [] },
        },
        {
            page: DASHBOARD_TABS.AD_GROUPS,
            detailItem: null,
            loading: { table: false, badge: false, chart: false },
            error: { table: false, badge: false, chart: false },
            tryAgain: { table: false, badge: false, chart: false },
            badgeOptions: null,
            selectedData: [],
            automationType: 'APP',
            automationQueryType: 'ADGROUP',
            requestLevel: FILTER_LEVELS.AD_GROUP,
            refreshSigner: true,
            refresh: false,
            filterParam: null,
            filterableColumns: AD_GROUP_FILTER_COLUMNS,
            removedFilterObject: { item: {}, remainingItems: [] },
        },
        {
            page: DASHBOARD_TABS.KEYWORDS,
            detailItem: null,
            loading: { table: false, badge: false, chart: false },
            error: { table: false, badge: false, chart: false },
            tryAgain: { table: false, badge: false, chart: false },
            badgeOptions: null,
            selectedData: [],
            automationType: 'CAMPAIGN',
            automationQueryType: 'KEYWORD',
            requestLevel: FILTER_LEVELS.KEYWORD,
            refreshSigner: true,
            refresh: false,
            filterParam: null,
            filterableColumns: KEYWORD_FILTER_COLUMNS,
            removedFilterObject: { item: {}, remainingItems: [] },
        },
        {
            page: DASHBOARD_TABS.SEARCH_TERMS,
            detailItem: null,
            loading: { table: false, badge: false, chart: false },
            error: { table: false, badge: false, chart: false },
            tryAgain: { table: false, badge: false, chart: false },
            badgeOptions: null,
            selectedData: [],
            automationType: 'KEYWORD',
            automationQueryType: 'SEARCH_TERM',
            requestLevel: FILTER_LEVELS.SEARCH_TERM,
            refreshSigner: true,
            refresh: false,
            filterableColumns: SEARCH_TERM_FILTER_COLUMNS,
            removedFilterObject: { item: {}, remainingItems: [] },
        },
        {
            page: DASHBOARD_TABS.ADS,
            loading: { table: false, badge: false, chart: false },
            error: { table: false, badge: false, chart: false },
            tryAgain: { table: false, badge: false, chart: false },
            badgeOptions: null,
            selectedData: [],
            requestLevel: FILTER_LEVELS.ADS,
            filterableColumns: AD_FILTER_COLUMNS,
            removedFilterObject: { item: {}, remainingItems: [] },
        },
        {
            page: DASHBOARD_TABS.CPP,
            loading: { table: false, badge: false, chart: false },
            error: { table: false, badge: false, chart: false },
            tryAgain: { table: false, badge: false, chart: false },
            badgeOptions: null,
            selectedData: [],
            requestLevel: FILTER_LEVELS.CPP,
            filterableColumns: CPP_FILTER_COLUMNS,
            removedFilterObject: { item: {}, remainingItems: [] },
        },
        {
            page: DASHBOARD_TABS.NEGATIVE_KEYWORDS,
            detailItem: null,
            loading: { table: false, badge: false, chart: false },
            error: { table: false, badge: false, chart: false },
            tryAgain: { table: false, badge: false, chart: false },
            badgeOptions: null,
            selectedData: [],
            automationType: 'CAMPAIGN',
            automationQueryType: 'CAMPAIGN',
            requestLevel: FILTER_LEVELS.NEGATIVE,
            refreshSigner: true,
            refresh: false,
            filterParam: null,
            defaultSort: { prop: 'negativeKeyword', order: 'ASC' },
            filterableColumns: NEGATIVE_KEYWORD_FILTER_COLUMNS,
            removedFilterObject: { item: {}, remainingItems: [] },
        },
    ]);

    const setReportLevel = (level) => {
        reportLevel.value = ACTIVE_NAME_REPORT_LEVEL_MAPPING[level];
    };
    const toggleCumulative = () => {
        showChartAsCumulative.value = !showChartAsCumulative.value;
    };
    return {
        reportLevel,
        showChartAsCumulative,
        tabs,
        tabFilters,
        preSelectedData,
        setReportLevel,
        adsManagerChartGranularity,
        toggleCumulative,
        updatePreSelectedData,
        updateFilterList,
        updateTab,
    };
});
