import { makeRequestNoAuth } from '@/common/MaRequestPooler';
import { createReferenceParamsForSignUp, getAuthRedirectUri } from '@/common/MaUtils.mjs';
import { PLATFORM } from '@/controllers/index';

export class AuthPublicController {
    constructor(pooler) {
        this.requestPooler = pooler || makeRequestNoAuth;
    }
    /**
     * Gateway only login. Note this does not use the dashboard one so no dashboard endpoints will work.
     * @param username
     * @param password
     * @returns {*|Promise<any>}
     */
    loginGateway({ username, password, userResponse }) {
        return this.requestPooler('/authentication/sign-in', {
            method: 'POST',
            data: {
                username,
                password,
                userResponse,
                platform: PLATFORM.SEARCHADS,
            },
        }, {}, true);
    }

    /**
     * @param info
     * @param registerType
     * @returns {unknown}
     */
    register(info, registerType) {
        return this.requestPooler('/authentication/sign-up', {
            method: 'POST',
            data: Object.assign(createReferenceParamsForSignUp(registerType) || {}, {
                platform: PLATFORM.SEARCHADS,
                ...info,
            }),
        });
    }

    /**
     * @param userId
     * @returns {unknown}
     */
    resendEmail(userId) {
        return this.requestPooler(`/authentication/user/${ userId }/resend`, {
            method: 'POST',
        });
    }

    /**
     * @param token
     * @returns {unknown}
     */
    verifyUser(token) {
        return this.requestPooler(`/authentication/sign-up/verify/${ token }?platform=${ PLATFORM.SEARCHADS }`, {
            method: 'POST',
            data: {
                platform: PLATFORM.SEARCHADS,
            },
        }, {}, true);
    }

    /**
     *
     * @param type
     * @param code
     * @returns {unknown}
     */
    socialLoginRequest(type, code) {
        return this.requestPooler(`/authentication/sign-in/${ type }`, {
            method: 'POST',
            data: Object.assign(createReferenceParamsForSignUp()||{}, {
                code,
                redirectUri: getAuthRedirectUri(type),
                platform: PLATFORM.SEARCHADS,
            }),
        }, {}, true);
    }

    appleLoginRequest(data) {
        return this.requestPooler(`/authentication/sign-in/apple`, {
            method: 'POST',
            data,
        }, {}, true);
    }

    loginWithGoogle(code) {
        return this.socialLoginRequest('google', code);
    }

    loginWithLinkedIn(code) {
        return this.socialLoginRequest('linkedin', code);
    }

    loginWithApple(data) {
        return this.appleLoginRequest(data);
    }

    /**
     * Start SSO
     * @param data: { username: String } //email
     * @returns {Promise} //SSO Discovery
     */
    startSSO(data) {
        return this.requestPooler(`/authentication/sso/sign-in`, {
            method: 'POST',
            data,
        });
    }

    /**
     *
     * @param email
     * @param userResponse
     * @returns {unknown}
     */
    requestRecovery({ email, userResponse }) {
        return this.requestPooler(`/password-reset/send`, {
            method: 'POST',
            data: {
                username: email,
                userResponse,
                platform: PLATFORM.SEARCHADS,
            },
        });
    }

    /**
     *
     * @param token
     * @returns {unknown}
     */
    getRecoveryInfoByToken(token) {
        return this.requestPooler(`/password-reset/${ token }`);
    }

    /**
     *
     * @param data
     * @returns {unknown}
     */
    submitRecovery(data) {
        return this.requestPooler('/password-reset/reset', {
            method: 'POST',
            data,
        });
    }
}

export const useAuthPublicController = () => new AuthPublicController();
