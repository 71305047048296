<template>
    <template v-if="showMinorVersionAvailable">
        <ma-tooltip :title="t('components.updateChecker.newVersionAvailableRefresh')">
            <ma-badge
                shape="square"
                size="large"
                :variant="minorVersionDiff >= 2 ? 'red' : 'blue'"
                type="secondary"
                icon="refresh-2"
                icon-alignment="right"
                class="cursor-pointer h-8"
                @click="reloadPage"
            >
                {{ t('components.updateChecker.newVersionAvailable') }}
            </ma-badge>
        </ma-tooltip>
    </template>
    <ma-modal
        :visible="isMajor"
        :ok-text="t('components.updateChecker.refreshNow')"
        :cancel-button-props="{ disabled: true, size: 'small' }"
        :ok-button-props="{ size: 'small' }"
        :closable="false"
        :mask-closable="false"
        type="confirm"
        @ok="() => reloadPage()"
    >
        <template #body-title>
            {{ t('components.updateChecker.newVersionAvailable') }}
        </template>
        <template #content>
            <div class="ma-body-4-medium text-gray-400 text-center">
                {{ t('components.updateChecker.newVersionAvailableRefreshMajor') }}
            </div>
        </template>
        <template #footer>
            <ma-button type="grey-link" disabled class="w-full">
                {{ t('components.updateChecker.willRefreshIn') }}
                <ma-countdown
                    :secs="60"
                    :show-seconds="true"
                    class="!w-fit"
                    @time-up="() => reloadPage()"
                />
            </ma-button>
        </template>
    </ma-modal>
</template>

<script setup>
    import MaCountdown from './MaCountdown.vue';
    import { useRegisterSW } from 'virtual:pwa-register/vue';
    import { computed, ref, onMounted, onUnmounted } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { usePublicController } from '@/controllers/PublicController';
    import { useMaStorage } from '@/composables/useMaStorage';
    import { useGlobal } from '@/composables/index';
    import { MaBadge, MaButton, MaModal, MaTooltip } from '@mobileaction/action-kit';
    import { MODE } from '@/components/MaUpdateChecker/enum';

    const { $log } = useGlobal();
    const { t } = useI18n();
    const appVersion = useMaStorage('appVersion', '0.0.0', localStorage);
    const BREAKING_LEVEL = 2;
    const isMajor = ref(false);
    const isMinorUpdate = ref(false);
    const minorVersionDiff = ref(0);
    const publicService = usePublicController();
    const ONE_MINUTE_MS = 60 * 1000; // Check every 1 minute
    const DEBUG_MODE = false; // enable debug mode to log to console in review apps

    defineOptions({
        name: 'ma-update-checker',
    });
    const props = defineProps({
        mode: { type: String, required: false, default: MODE.MODAL }, // badge|modal
    });

    const isBadge = computed(() => props.mode === MODE.BADGE);
    const showMinorVersionAvailable = computed(() => isBadge.value && isMinorUpdate.value);
    const checkNewAppVersion = async () => {
        try {
            const data = await publicService.getSaVersionInfo();

            $log.debug('Received version data:', data);
            if (!data || !data.version) {
                $log.warn('Failed to get version with data: ', data);
                return;
            }
            const { version } = data;
            // this is used to persist between instances on same tab
            const oldVersion = appVersion.value;
            appVersion.value = version;
            if (!oldVersion || oldVersion === '0.0.0') {
                return;
            }
            const ovp = oldVersion.split('.');
            const nvp = version.split('.');
            for (let i = 0; i < 2; ++i) { //last digit is minor version
                if (ovp[i] !== nvp[i]) { //checks for difference in 1st and 2nd numbers
                    isMajor.value = i < BREAKING_LEVEL;
                    break;
                }
            }
            if (!isMajor.value) {
                minorVersionDiff.value = nvp[2] - ovp[2];
            }
        } catch (e) {
            const [...args] = e;
            const { errors } = (args.length && args[0]) || {};
            // ignore fetch errors that happen during deploys or peak times
            if (errors && (errors || []).indexOf('FETCH_ERROR') === -1) {
                $log.error('Failed to get ma app version: ' + JSON.stringify(args));
            }
            $log.error('Failed to get version:', ...args);
        }
    };

    const devDebug = (...args) => {
        if (DEBUG_MODE) { // only log in dev mode to avoid cluttering the console
            $log.debug(...args);
        }
    };

    const {
        // offlineReady,
        needRefresh,
        updateServiceWorker,
    } = useRegisterSW({
        immediate: true,
        onRegisteredSW(swUrl, registration) {
            if (DEBUG_MODE) {
                // Store service worker registration and URL on window for debugging
                window.r = registration;
                window.swUrl = swUrl;
                window.updateServiceWorker = updateServiceWorker;
                window.checkNewAppVersion = checkNewAppVersion;
            }
            // Only set up interval if we have a registration
            registration && setInterval(async () => {
                devDebug('Checking for service worker updates...', {
                    serviceWorkerUrl: swUrl,
                    registration: registration,
                });

                // Ensure service worker is ready and not currently installing
                if (!(!registration.installing && navigator)) {
                    devDebug('Service worker not ready or currently installing');
                    return;
                }

                // Check browser online status if connection API available
                if (('connection' in navigator) && !navigator.onLine) {
                    devDebug('Browser is offline, skipping update check');
                    return;
                }

                try {
                    // Check if service worker has an update available
                    devDebug('Checking for service worker updates...');
                    await registration.update();

                    // Get the waiting service worker if one exists
                    devDebug('Waiting service worker:', registration.waiting);
                    const waitingServiceWorker = registration.waiting;

                    if (waitingServiceWorker) {
                        devDebug('New service worker version found, waiting for update...');
                        // waiting for update
                        // once the update is installed,
                        // user will be prompted to refresh the page either via badge or refresh modal
                    } else {
                        devDebug('No new service worker version found');
                    }
                } catch (error) {
                    devDebug('Error checking for service worker updates:', error);
                }

            }, ONE_MINUTE_MS);
        },

        // Handle when new content is available
        onNeedRefresh: async () => {
            $log.debug('New app version available, checking version info...');
            await checkNewAppVersion();
            openVersionUpdateNotification();
        },

        // Handle service worker registration errors
        onRegisterError: (error) => {
            $log.error('Service worker registration failed:', error);
        },
    });

    const reloadPage = async () => {
        await updateServiceWorker(true);
    };

    const openVersionUpdateNotification = () => {
        $log.debug('version update', isMajor.value);
        if (isMajor.value) {
            return;
        }
        if (isBadge.value) {
            isMinorUpdate.value = true;
            return;
        }
    };


    const beforeUnloadListener = async () => {
        // on users self refresh, call updateServiceWorker if needed
        if (needRefresh.value) {
            await updateServiceWorker();
        }

        // returning null does not prompt the user
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#compatibility_notes
        return null;
    };

    onMounted(() => window.addEventListener('beforeunload', beforeUnloadListener));
    onUnmounted(() => window.removeEventListener('beforeunload', beforeUnloadListener));
</script>
