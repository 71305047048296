{
    "buttons": {
        "clearFilters": "清除过滤器",
        "OK": "好的",
        "actions": "操作",
        "adScheduling": "广告调度",
        "add": "添加",
        "addAsKeyword": "添加为关键字",
        "addAsNegativeKeyword": "添加为否定关键词",
        "addKeywords": "添加关键词",
        "addKeywordsToAdGroup": "为广告组添加关键字",
        "addNegativeKeywords": "添加否定关键词",
        "addNew": "添加新内容",
        "addSlackChannel": "添加 Slack 频道",
        "adjust": "调整",
        "apply": "申请",
        "assignCategory": "指定类别",
        "audience": "观众",
        "automationCart": "自动化购物车",
        "automationRule": "自动化规则",
        "back": "返回",
        "cancel": "取消",
        "change": "改变",
        "chart": "图表",
        "checkStatus": "检查状态",
        "choose": "选择",
        "clear": "清除",
        "clearAll": "全部清除",
        "close": "关闭",
        "collapse": "崩溃",
        "collapseAll": "全部折叠",
        "confirm": "确认",
        "connectNow": "立即连接",
        "contactSales": "联系销售",
        "continueToPreview": "继续预览",
        "copy": "复制",
        "copyUrl": "复制 URL",
        "create": "创建",
        "createAd": "创建广告",
        "createAdGroup": "创建广告组",
        "createAnother": "创建另一个",
        "createAutomationRule": "创建自动化规则",
        "createCampaign": "创建活动",
        "createConversion": "创建转换",
        "createCreativeSet": "创建创意套装",
        "createGoal": "创建目标",
        "createIntegration": "创建集成",
        "createKeyword": "创建关键字",
        "createNegativeKeyword": "创建否定关键词",
        "createSearchTabAutomation": "创建搜索选项卡自动化",
        "delete": "删除",
        "discard": "丢弃",
        "discardDraft": "弃权草案",
        "dismiss": "解散",
        "duplicate": "重复",
        "done": "完毕",
        "edit": "编辑",
        "expand": "展开",
        "find": "查找",
        "goalSelection": "目标选择",
        "joinBeta": "加入测试版",
        "label": "标签",
        "learn": "学习",
        "learnMore": "了解更多",
        "linkItLater": "稍后链接",
        "loadMore": "加载更多",
        "logs": "日志",
        "newTab": "新标签",
        "next": "下一页",
        "openAutomationCart": "打开自动化购物车",
        "openInNewTab": "在新浏览器标签页中打开",
        "refresh": "刷新",
        "reloadPage": "立即重新加载",
        "remove": "移除",
        "replay": "重播",
        "save": "节省",
        "saveAndClose": "保存并关闭",
        "seeExample": "参见示例",
        "seeAll": "查看全部",
        "send": "发送",
        "shareOn": "分享到",
        "start": "开始",
        "templates": "模板",
        "tryAgain": "再试一次",
        "update": "更新",
        "hide": "",
        "saveChanges": "",
        "show": ""
    },
    "columns": {
        "currency": "货币",
        "CR": "转化率(CR)",
        "TTR": "TTR",
        "action": "行为",
        "actionType": "行动类型",
        "active": "有效",
        "adChannel": "广告频道",
        "adGroupId": "广告组编号",
        "adGroupName": "广告组名称",
        "allAdGroups": "所有广告组",
        "allCampaigns": "所有活动",
        "allKeywords": "所有关键词",
        "appliedData": "应用数据",
        "automationLevel": "自动化水平",
        "averageCPA": "平均CPA",
        "averageCPT": "平均CPT",
        "avgCPA": "平均CPA",
        "avgCPT": "平均CPT",
        "bidAmount": "出价金额",
        "broadMatch": "广泛匹配",
        "budget": "预算",
        "campaignID": "活动编号",
        "campaignName": "活动名称",
        "campaignType": "广告系列类型",
        "category": "类别",
        "chance": "Chance",
        "change": "改变",
        "clicks": "点击次数",
        "conditions": "条件",
        "conversionRates": "转化率CR（CR）",
        "conversions": "转换",
        "cpa": "CPA",
        "cpaGoal": "目标CPA",
        "cpm": "CPM",
        "cpt": "CPT",
        "cptBid": "CPT 投标",
        "cr": "转化率(CR)",
        "creativeSetId": "创意套装 ID",
        "dailyCap": "日预算上限",
        "dataCheckRange": "数据检查范围",
        "dataComparisonRange": "数据压缩范围",
        "dateOccured": "发生日期",
        "defaultCPMBid": "默认CPM出价",
        "defaultCPTBid": "默认CPT出价",
        "defaultMaxCpmBid": "默认最大 CPM 出价",
        "defaultMaxCptBid": "默认最大 CPT 出价",
        "difficultyRank": "有机困难",
        "email": "电子邮件",
        "emailNotifier": "电子邮件通知",
        "exactMatch": "完全匹配",
        "excludeReAttribution": "不包括再分配",
        "goal": "目标",
        "goals": "目标设置",
        "impressions": "展示次数",
        "includeReAttribution": "包括重新分配",
        "includedInGoal": "包括在目标中",
        "install": "安装数",
        "installs": "安装数",
        "invoicingOption": "开票选项",
        "iphoneChance": "Iphone 机会",
        "keyword": "关键词",
        "keywordId": "关键字 Id",
        "keywords": "关键词",
        "label": "标签",
        "lifetimeBudget": "终身预算",
        "ltv": "LTV",
        "matchSource": "匹配来源",
        "matchType": "匹配类型",
        "maxCPTBid": "最高每次点击费用出价",
        "maxCpmBid": "最大 CPM 出价",
        "name": "名称",
        "negativeKeywords": "否定关键词",
        "newBid": "新投标",
        "newBudget": "新预算",
        "numberOfApps": "应用程序数量",
        "numberOfIphoneApps": "IPhone 应用程序数量",
        "oldBid": "旧投标",
        "oldBudget": "旧预算",
        "onlyReAttribution": "仅重新归因",
        "organicRank": "自然排名",
        "passive": "被动式",
        "password": "密码",
        "percentage": "百分比",
        "popularity": "流行度",
        "popularityRank": "流行度",
        "queryType": "查询类型",
        "reAttrType": "再归因设定",
        "reAttribution": "再归因",
        "reSend": "重新发送",
        "recover": "重置",
        "redownloadsShare": "重新下载 共享",
        "restrictionEntityType": "查询类型",
        "restrictionLevel": "限制级别",
        "roas": "ROAS",
        "ruleActionFrequency": "操作频率",
        "ruleControlFrequency": "控制频率",
        "ruleId": "规则",
        "ruleLogState": "运行状态",
        "ruleName": "规则名称",
        "ruleState": "规则国",
        "searchMatch": "搜索匹配",
        "searchTerm": "搜索条件",
        "source": "资料来源",
        "spend": "支出",
        "state": "状态",
        "status": "状态",
        "storefront": "店面",
        "taps": "点击次数",
        "toBeAdded": "待添加",
        "ttr": "TTR",
        "updateTime": "更新时间",
        "withOutReAttribution": "仅新用户下载",
        "withReAttribution": "包含重新归因",
        "newDownloadsShare": "",
        "emailNotEditable": ""
    },
    "device": {
        "IPHONE": "iPhone",
        "IPAD": "iPad",
        "IPHONE_AND_IPAD": "iPhone 和 iPad"
    },
    "common": {
        "MALE": "男性",
        "FEMALE": "女性",
        "MALE_FEMALE": "男性和女性",
        "wellBeBackSoon": "我们很快就回来！",
        "sorryForInconvenience1": "很抱歉给您带来不便，我们目前正在进行维护。如有需要，您可以 ",
        "sorryForInconvenience2": "否则，我们很快就会重新上线！",
        "adsPerformances": "广告表演",
        "adsPerformanceFor": "广告性能{adGroup}",
        "adsTopFive": "图表显示了根据转化率排名前 5 位的广告。您可以点击",
        "adsToNavigate": "查看该广告组中所有广告的效果。",
        "addLabelsFor": "添加标签",
        "allData": "所有数据",
        "action": "行为",
        "alreadyInBudgetAllocationGroup": "无法选择此活动，因为它已在预算分配组中。",
        "alreadyInBudgetAllocationGroupAll": "{appName} 下的所有活动都已列入预算拨款。",
        "bulkActions": {
            "CHANGE_BID": "更新关键字出价",
            "CHANGE_DEFAULT_CPC_BID": "更新默认 CPT 投标",
            "CHANGE_DAILY_CAP": "更改每日预算",
            "CHANGE_CPA_GOAL": "更改 CPA 目标",
            "ADD_LABEL": "添加标签",
            "NEW_ACTION": "",
            "PAUSE": "暂停"
        },
        "changeOrClearFilters": "请更改或清除过滤器",
        "confirmDescription": "我确认此批量行动将适用于{number} 项目，且此行动不可逆转",
        "cryptoCurrencies": "加密货币",
        "csv": "CSV格式",
        "currentView": "当前视图",
        "emailPreview": "电子邮件预览",
        "entitySearchPlaceholder": {
            "AD_GROUP": "搜索广告组",
            "CAMPAIGN": "搜索活动",
            "KEYWORD": "搜索关键词",
            "NEGATIVE": "搜索否定关键词",
            "SEARCH_TERM": "搜索关键词"
        },
        "error": "错误",
        "integrated": "完成集成",
        "link": "链接",
        "showCumulative": "显示累计",
        "showValues": "显示价值",
        "myApps": "我的应用程序",
        "numberMetadataSelected": "已选择{count} 个 {type}",
        "noDataForFilter": "没有与您的筛选器匹配的数据！",
        "nAdGroupLabelSelected": "未选择广告组标签 |{n} 已选择广告组标签 |{n} 已选择广告组标签",
        "nCampaignLabelSelected": "未选择活动标签 |{n} 已选择活动标签 |{n} 已选择活动标签",
        "nKeywordLabelSelected": "未选择关键字标签 | 已选择{n} 关键字标签 | 已选择{n} 关键字标签",
        "noActionLogDetails": "没有该行动日志的详细信息",
        "noAdsPerformace": "所选时间范围内无广告性能",
        "noDataWithCurrentFilters": "当前过滤器未找到数据",
        "onlySelectedData": "仅选取数据",
        "recentlyViewed": "最近浏览",
        "results": "成果",
        "searchNew": "搜索...",
        "searchMatch": {
            "false": "关闭",
            "true": "开启"
        },
        "selectMetadata": "选择{type}",
        "suggested": "建议",
        "exclusive": "独家",
        "users": "用户",
        "selectedOption": "该选项将只对您所选的项目进行更改。",
        "currentOption": "该选项将只对显示页面上的项目进行更改。",
        "allOption": "该选项将根据当前级别和筛选器对所有项目进行更改。请注意，此操作可能需要一些时间。",
        "ADD_AS_KEYWORD": "添加为关键词",
        "ADD_AS_NEGATIVE_KEYWORD": "添加为否定关键词",
        "ADD_LABEL": "添加标签",
        "ALL": "所有",
        "AND": "和",
        "APPLY_SMART_NOTIFICATION": "应用智能通知",
        "APPLY_SMART_BID_SWEET_SPOT": "应用智能投标甜点",
        "CHANGE_BID": "变更投标",
        "CHANGE_BUDGET": "更改预算",
        "CHANGE_CPA_GOAL": "更改 CPA 上限",
        "CHANGE_DAILY_CAP": "更改每日预算",
        "CHANGE_TOTAL_BUDGET": "改变终身预算",
        "CONTAINS": "包含",
        "DECREASE": "减少",
        "EMAIL_NOTIFICATION": "电子邮件通知",
        "EMPTY": "不适用",
        "GREATER_THAN": "大于",
        "INCREASE": "增加",
        "IN_RANGE": "范围内",
        "LESS_THAN": "小于",
        "LIMIT": "限制：{limit}",
        "NOT_CONTAINS": "不包含",
        "NOT_IN_RANGE": "不在范围内",
        "PAUSE": "暂停",
        "SET": "设置为",
        "SET_TO_CPA": "设置为平均 CPA",
        "SET_TO_CPT": "设置为 CPT 平均值",
        "SLACK_NOTIFICATION": "Slack通知",
        "UTC": "世界协调时",
        "accessRequired": "需要访问",
        "accountIntegrationsExpired": "{orgName} 的整合无效，请提供有效的整合账户或联系我们。单击此警告立即集成！",
        "accounts": "广告组",
        "add": "添加",
        "actionFrequency": "操作频率",
        "activate": "激活",
        "activeOrganization": "活跃组织：{org}",
        "adGroup": "广告组",
        "adGroupSettings": "广告组设置",
        "adGroupStatus": "广告组状态",
        "adGroups": "广告组",
        "adPerformance": "广告效果",
        "adPlacement": "广告展示位置",
        "adPlacements": {
            "APPSTORE_PRODUCT_PAGES_BROWSE": "产品页面--浏览时",
            "APPSTORE_SEARCH_RESULTS": "搜索结果",
            "APPSTORE_SEARCH_TAB": "搜索选项卡",
            "APPSTORE_TODAY_TAB": "今日标签"
        },
        "ads": "广告",
        "adsStructure": "广告结构",
        "ageRange": "年龄范围",
        "all": "全部",
        "alwaysTranslate": "始终显示关键字的英文翻译",
        "allUsers": "所有用户",
        "amount": "金额",
        "and": "和",
        "apiKey": "Api 密钥",
        "app": "应用程序",
        "appName": "应用程序名称",
        "apps": "应用程序",
        "areaChart": "区域图",
        "attribution": "归因",
        "audience": "观众",
        "automationEntityList": "自动化实体列表",
        "available": "可用",
        "basic": "基本",
        "beta": "BETA",
        "between": "之间",
        "bidAmountHistory": "投标历史",
        "bidSources": {
            "DASHBOARD": "仪表板",
            "RULE": "自动化",
            "EXTERNAL": "外部",
            "SMART_BID": "智能投标",
            "BUDGET_ALLOCATION": "预算分配",
            "QUICK_AUTOMATION": "快速自动化",
            "AI_KEYWORD_OPTIMIZER": ""
        },
        "budgetOrder": "预算订单",
        "bulkUpdate": "批量更新",
        "campaign": "广告系列",
        "campaignSetting": "活动设置",
        "campaignStatus": "广告系列状况",
        "campaigns": "广告系列",
        "categories": {
            "BRAND": "#品牌",
            "CATEGORY": "# 类别",
            "COMPETITOR": "#Competitor",
            "DISCOVERY": "#发现",
            "UNASSIGNED": "#未分配",
            "description": {
                "AGE": "当你以特定性别或年龄组为目标时，Apple 不会向 \"限制广告跟踪\"（LAT）选项为 \"开 \"的用户显示你的广告。",
                "BRAND": "以搜索您的应用程序或公司名称的客户为重点开展品牌宣传活动。",
                "BROAD": "广泛匹配可确保您的广告在关键字的相关近似变体上运行，如单数、复数、拼写错误、同义词、相关搜索以及完全或部分包含该词的短语。",
                "COMPETITOR": "竞争对手营销活动的重点是在相同或相关应用程序类别中搜索与您类似应用程序的客户。",
                "DISCOVERY": "发现活动侧重于寻找与您的应用程序相关的新搜索词。",
                "EXACT": "要对广告可能出现的搜索进行最严格的控制，请使用精确匹配。",
                "GENDER": "当你以特定性别或年龄组为目标时，Apple 不会向 \"限制广告跟踪\"（LAT）选项为 \"开 \"的用户显示你的广告。",
                "GENERIC": "通用营销活动主要针对搜索非品牌术语的客户，这些术语描述了您的应用程序类别以及您的应用程序的功能。",
                "LAT": "当你以特定性别或年龄组为目标时，Apple 不会向 \"限制广告跟踪\"（LAT）选项为 \"开 \"的用户显示你的广告。"
            }
        },
        "changeContentFromSidebar": "你可以从左侧栏中更改内容。",
        "chart": "图表",
        "chartType": "图表类型",
        "chartFilters": {
            "customColumnNotApplicable": "自定义列只能在日线图视图中使用",
            "monthlyChartNotAvailable": "选择自定义列字段后，月度图表选项不可用",
            "weeklyChartNotAvailable": "选择自定义列字段后，每周图表选项不可用"
        },
        "cohortAnalysis": "队列分析",
        "columnChart": "柱状图",
        "columns": "专栏",
        "comingSoon": "即将推出",
        "computed": "计算",
        "confirm": "确认",
        "contactUsTerm1": "联系我们",
        "contactUsTerm2": "如果您需要帮助，请随时联系我们！",
        "contains": "包含",
        "copy": "复制",
        "countriesAndRegions": "国家和地区",
        "country": "国家",
        "countryCodes": "国家代码",
        "cpp": "人民党",
        "createdAt": "创造于",
        "creativeSetSettings": "创意设置",
        "creativeSets": "Creative Sets",
        "currency": "货币",
        "currencyCode": "代码 ({code})",
        "currencySymbol": "符号 ({currency})",
        "currencyView": "货币视图",
        "customColumns": "自定义列",
        "customConversion": "自定义转换",
        "customerType": "用户类型",
        "daily": "每日",
        "dailyBudget": "日预算上限",
        "dailyBudgetHistory": "每日预算历史",
        "dashboard": "仪表板",
        "dataRange": "数据范围",
        "defaultColumns": "默认列",
        "delete": "删除",
        "devices": "设备",
        "displayStatus": "显示状态",
        "download": "下载",
        "downloadCsv": "以 CSV 格式下载数据",
        "dontTranslate": "不显示关键字的英文翻译",
        "duplicate": "重复",
        "editColumns": "编辑列",
        "enable": "启用",
        "enabled": "启用",
        "endDate": "结束日期",
        "enterNewBindAmount": "输入新的出价金额",
        "equals": "等于",
        "events": "事件",
        "failed": "失败",
        "filter": "过滤器",
        "filters": "过滤器",
        "for": "对于",
        "formula": "计算公式",
        "frequency": "频率",
        "from": "从",
        "gender": "性别",
        "goal": "目标",
        "greaterThan": "大于",
        "here": "这里",
        "hideChart": "隐藏图表",
        "hourly": "每小时",
        "in": "在",
        "inAppConversions": "应用内转换",
        "inAppEventsSettings": "应用内事件设置",
        "inQueue": "排队中",
        "info": "信息",
        "insights": "洞察力",
        "integratedOrgs": "综合活动小组",
        "integration": "一体化",
        "integrationId": "集成 ID：",
        "integrationStatus": "整合状态",
        "integrations": {
            "partners": {
                "adjust": "调整",
                "airbridge": "空桥",
                "appsflyer": "AppsFlyer",
                "branch": "分支机构",
                "custom": "内部",
                "kochava": "科恰瓦",
                "singular": "单数",
                "tenjin": "天神"
            }
        },
        "is": "是",
        "isNot": "不是",
        "keywordStatus": "关键词状态",
        "keywords": "关键词",
        "language": "语言",
        "lessThan": "小于",
        "splineChart": "花键图表",
        "lineChart": "折线图",
        "loading": "正在加载...",
        "logs": "日志",
        "makeSelection": "请选择",
        "message": "信息",
        "monthly": "每月",
        "more": "更多信息",
        "multiLoginDetected": "检测到来自其他浏览器的登录活动，您将被重定向到登录页面",
        "multiple": "多个",
        "nAccount": "无活动小组 |{n} 活动小组 |{n} 活动小组",
        "nAccountsSelected": "未选择活动组 | 已选择{n} 活动组 | 已选择{n} 活动组",
        "nAdGroup": "无广告组 |{n} 广告组 |{n} 广告组",
        "nAdGroupsSelected": "未选择广告组 | 已选择{n} 广告组 | 已选择{n} 广告组",
        "nApp": "无应用程序 |{n} 应用程序 |{n} 应用程序",
        "nAppsSelected": "未选择应用程序 | 已选择{n} 应用程序 | 已选择{n} 应用程序",
        "nAdPlacementSelected": "未选择广告投放 | 已选择{n} 广告投放 | 已选择{n} 广告投放",
        "nLabelSelected": "未选择标签 | 已选择{n} 标签 | 已选择{n} 标签",
        "nStatusSelected": "未选择状态 |{n} 已选择状态 |{n} 已选择状态",
        "nAddedAsKeywordSelected": "未添加为已选关键词 |{n} 已添加为已选关键词 |{n} 已添加为已选关键词",
        "nDeviceClassSelected": "无设备 |{n} 设备 |{n} 设备",
        "nGenderSelected": "无性别 |{n} 性别 |{n} 性别",
        "nCustomerTypeSelected": "无客户类型 |{n} 客户类型 |{n} 客户类型",
        "nBidStrengthSelected": "无出价优势 |{n} 出价优势 |{n} 出价优势",
        "nCreativeTypeSelected": "无创意类型 |{n} 创意类型 |{n} 创意类型",
        "nCampaign": "无活动 |{n} 活动 |{n} 活动",
        "nCampaignsSelected": "未选择活动 | 已选择{n} 活动 | 已选择{n} 活动",
        "nCountriesSelected": "未选择国家 |{n} 已选择国家 |{n} 已选择国家",
        "nCountry": "没有国家 |{n} 国家 |{n} 国家",
        "nCreativeSet": "无创意集 |{n} 创意集 |{n} 创意集",
        "nGoal": "无目标 |{n} 目标 |{n} 目标",
        "nKeyword": "无关键字 |{n} 关键字 |{n} 关键字",
        "nKeywordsSelected": "未选择关键字 | 已选择关键字{n} | 已选择关键字{n} ",
        "nCPPSelected": "未选择 CPP | 已选择{n} CPP | 已选择{n} CPP",
        "nRulesSelected": "未选择规则 | 已选择规则{n} | 已选择规则{n} ",
        "negativeKeywords": "否定关键词",
        "new": "新",
        "newFeature": "新功能可用！",
        "newUsers": "新用户",
        "no": "没有",
        "noBidChangeInRange": "在选定的时间范围内，出价没有变化",
        "noBudgetChangeInRange": "所选时间范围内的每日预算无变化",
        "noData": "没有数据",
        "noFiltersApplied": "尚未应用筛选器",
        "noGoalSelection": "无目标选择",
        "noIntegration": "没有连接",
        "noIntegrationAdded": "尚未添加整合功能！",
        "notBetween": "非之间",
        "notContains": "不包含",
        "notEquals": "不相等",
        "notScheduled": "未安排",
        "onHold": "搁置",
        "operation": "运行",
        "optional": "可选",
        "or": "或",
        "organization": "活动小组",
        "organizations": "广告组",
        "passed": "通过",
        "pause": "暂停",
        "paused": "已停止",
        "preparing": "准备工作",
        "preview": "预览",
        "processing": "加工",
        "processingLevel": "加工{level}...",
        "promoText": "宣传文本",
        "promotedApp": "推广应用程序",
        "ranking": "排行",
        "reAttrType": "重新分配数据类型",
        "reason": "原因",
        "report": "报告",
        "reportingNote": "报告不是实时的。过去 24 小时内收到的数据可能不会反映在报告中",
        "resolved": "已解决",
        "requestTime": "申请时间",
        "retry": "重试",
        "returningUsers": "回访用户",
        "rules": "规则",
        "running": "运作中",
        "savedFilters": "保存的过滤器",
        "scheduleAds": "广告时间表",
        "scheduled": "计划",
        "search": "搜索",
        "searchApp": "搜索应用程序",
        "searchByMetric": "按指标搜索",
        "searchByName": "按名称搜索",
        "searchByNameOrId": "按姓名或 ID 搜索",
        "searchKeyword": "搜索关键字",
        "searchTerms": "搜索条件",
        "select": "选择",
        "selectACampaign": "选择活动",
        "selectAnAdGroup": "选择广告组",
        "selectCampaign": "选择活动",
        "selectCategory": "选择类别",
        "selectGoalInfo": "点击此处以查看移动衡量服务提供商的数据",
        "sessionHasExpired": "您的会话已过期，您将被重定向到登录页面",
        "settings": "设置",
        "showChart": "显示图表",
        "showSteps": "显示步骤",
        "smartBidding": "智能投标",
        "standardInvoice": "标准发票",
        "startDate": "开始日期",
        "status": "状态",
        "stop": "停止",
        "stopped": "已停止",
        "successful": "成功",
        "switchCurrencyView": "点击切换货币视图",
        "templates": "模板",
        "timeZone": "时区",
        "timezoneNote": "所有日期和时间的时区",
        "total": "总计",
        "totalCount": "总计{total}",
        "totalSummary": "总摘要",
        "type": "类型",
        "translationOn": "关于翻译",
        "translationOff": "翻译关闭",
        "update": "更新",
        "updated": "已更新",
        "updateKeywordBidAmount": "更新关键字出价金额",
        "upload": "上传",
        "uploadedIntegrations": "上传的集成",
        "usersOfMyOtherApps": "我其他应用程序的用户",
        "view": "查看",
        "warning": "警告",
        "weekly": "每周",
        "yes": "是",
        "youWillBeRedirected": "你将在 {autoRedirectTimer} 秒后自动重定向",
        "contactTeamAdministrator": "",
        "noSearchAdsAuthorization": "",
        "statuses": {
            "pending": ""
        },
        "exports": "导出"
    },
    "components": {
        "dateRangeSelector": {
            "last14Days": "最近 14 天",
            "custom": "定制",
            "last2Weeks": "最近两周",
            "last30Days": "最近 30 天",
            "last3Mths": "最近 3 个月",
            "last6Mths": "最近 6 个月",
            "last7Days": "最近 7 天",
            "lastMonth": "上个月",
            "lastWeek": "上周",
            "lastYear": "去年",
            "pickARange": "选择范围",
            "thisMonth": "本月",
            "thisWeek": "本周",
            "today": "今天",
            "week": "周数",
            "yesterday": "昨天"
        },
        "maColumnsManager": {
            "cannotMakePrivate": "不能制作不属于自己的私人预置",
            "upgradeForCustomColumns": "您需要升级才能创建更多自定义列",
            "upgradeForCohortColumns": "您需要升级才能应用 \"队列指标\"。",
            "clearAll": "全部清除",
            "dragDrop": "拖放重新排序",
            "duplicatePresetName": "预设名称已使用，请更改！",
            "editColumnsFor": "编辑栏 {table}",
            "makePrivate": "使私人",
            "makePublic": "公开",
            "nColumnsSelected": "{n} 所选列",
            "privatePreset": "私人预设",
            "privatePresets": "私人预设",
            "publicPreset": "公共预设",
            "publicPresets": "公共预设",
            "saveAsPreset": "保存为预设值",
            "searchByMetric": "按指标搜索",
            "selectAll": "全部选择"
        },
        "updateChecker": {
            "importantUpdateReleased": "发布重要更新",
            "newVersionAvailable": "新版本可用",
            "newVersionAvailableRefresh": "新版 SearchAds.com 仪表盘已发布。点击徽章后，仪表板将以最新功能刷新。",
            "newVersionAvailableRefreshMajor": "新版 SearchAds.com 控制面板已发布。您可以使用以下按钮立即刷新或等待 60 秒后刷新。",
            "pageWillRefreshIn": "页面将刷新，以便升级：",
            "refreshNow": "立即刷新",
            "refreshLater": "我稍后会刷新",
            "willRefreshIn": "将刷新： "
        },
        "appSearch": {
            "searchFailedWithErrorCode": "搜索失败，错误代码为",
            "searchForAnyAppByName": "按名称或 ID 搜索任何应用程序"
        },
        "campaignGroup": {
            "chooseCampaignGroup": "选择活动组",
            "selectAnIntegration": "选择集成"
        },
        "common": {
            "apply": "申请",
            "columnsToHide": "要隐藏的列",
            "columnsToShow": "显示栏",
            "resetToDefaults": "重置为默认值",
            "saveAsPreset": "保存为预置"
        },
        "countrySelector": {
            "selectAStoreFront": "选择店面"
        },
        "dataLevels": {
            "ACCOUNT": "活动小组",
            "AD": "广告",
            "AD_GROUP": "广告组",
            "APP": "应用程序",
            "CAMPAIGN": "广告系列",
            "CPP": "人民党",
            "KEYWORD": "关键词",
            "NEGATIVE": "否定关键词",
            "SEARCH_TERM": "搜索词",
            "CREATIVE_SET": ""
        },
        "goals": {
            "categories": {
                "ENGAGEMENT": "订婚",
                "GAME_EVENT": "游戏活动",
                "OTHER": "其他",
                "PURCHASE": "购买",
                "SUBSCRIPTION": "订阅",
                "TRIAL": "试用"
            }
        },
        "keywordAddition": {
            "BROAD": "广泛",
            "EXACT": "精确",
            "keywordType": "关键词类型",
            "negative": "阴性"
        },
        "loadingScreen": {
            "loadingSearchAds": "按移动操作搜索广告 | 正在加载..."
        },
        "saAuthHeader": {
            "adBlockersAreKnowToBlock": "众所周知，广告拦截器会拦截任何带有广告字样的内容。您可能会遇到一些意想不到的问题。为了避免这些问题，请在我们的产品上禁用它。",
            "ifYouAreUsingAdBlock": "如果您正在使用广告拦截器，请禁用它以获得更好的体验"
        }
    },
    "messages": {
        "info": {
            "signInWithAppleSearchAds": "使用 Apple 搜索广告登录",
            "toScrollUseShift": "使用 \"Shift + 鼠标滚动 \"向右滚动表格。",
            "actionTypeInfo": "通过预先定义的规则实施行动。",
            "adBlockersIsNotEnabled": "请确保本网站未启用广告拦截器。如果问题仍然存在，请联系我们",
            "addGoalTabInfo": "如果选择此选项卡，您可以描述 MMP 归因数据的新目标",
            "addingKeywordsToAdGroup": "将关键字添加到广告组...",
            "addingNegativeKeywordsToAdGroup": "在广告组中添加否定关键字...",
            "addingNegativeKeywordsToCampaign": "在广告系列中添加否定关键字...",
            "almostThere": "快到了",
            "amountMustBeGreaterThan0": "金额必须大于 0",
            "areYouSureDeleted": "您确定要删除所选项目吗？",
            "automationLevelInfo": "<br><br> 例如，\"应用程序中的关键词\"；在本例中，自动化级别为 \"关键词\"：应用程序中的关键字；在本例中，自动化级别为关键字",
            "budgetCanNotBeEmpty": "预算不能为空",
            "budgetValueIsNotValid": "预算值无效",
            "campaignNameCanNotBeEmpty": "活动名称不能为空",
            "campaignNameShouldBeUniqueForOrg": "账户内存在同名的广告系列",
            "chooseOneOrMoreCountries": "选择一个或多个国家或地区",
            "clickToDetail": "点击查看详情...",
            "clickToUpdate": "点击更新",
            "continueWithSSO": "继续 SSO",
            "copyReportLink": "复制报告链接",
            "createAccount": "创建活动小组",
            "creatingAdCreative": "创作广告创意...",
            "creatingAdGroup": "创建广告组...",
            "creatingCampaign": "创建活动...",
            "creatingCreativeSet": "创造有创意的场景...",
            "dailyBudgetCanNotBeEmpty": "每日预算不能为空",
            "dailyBudgetExplanation": "每日预算是您希望在一个月内每天花在营销活动上的平均金额。您的每月支出不会超过每日预算乘以日历月平均天数。在有机会获得更多下载的日子里，您的花费可能会超过每日预算。<br><br> 您的广告系列将根据您的每日预算金额每月持续花费，除非您暂停、删除或设置结束日期。如果您设置了结束日期，您的花费不会超过活动总天数乘以您的每日预算。<br><br> 您可以随时调整您的每日预算。",
            "dataIsFetching": "获取数据...",
            "dataProvidedBy": "数据由",
            "doNotHaveAnAccountYet": "还没有账户？",
            "enterABillingContactEmail": "请输入账单联系人电子邮件",
            "enterABuyerEmail": "请输入买家电子邮件",
            "enterABuyerName": "请输入买家姓名",
            "enterAClientName": "请输入客户名称",
            "enterAOrderNumber": "请输入订单号",
            "enterAValidBuyerEmail": "请输入有效的买家电子邮件",
            "enterAValidContactEmail": "请输入有效的联系电子邮件",
            "enterAValidEmail": "请输入有效的电子邮件",
            "goalTabInfo": "如果选择此选项卡，您将看到根据预定义自定义转换的 MMP 归因数据报告数据。",
            "haveToReloadPage": "在加载此页面时，您的内容已被更新。请刷新页面，否则页面将在 10 秒后重新加载。",
            "inputValidNumber": "请输入有效号码",
            "lifetimeBudgetExplanation": "终身预算是您希望在营销活动上花费的总金额。一旦您的营销活动开始运行，它就不能减少，而且不能用于新的或重复的营销活动。<br><br> 如果您想让您的营销活动持续运行，并优化您的支出以利用高机会时间，您可以随时切换到仅每日预算。一旦切换，您将无法在同一营销活动中再次使用终生预算。",
            "loadingDataFromServer": "从服务器加载数据...",
            "max500Row": "*只显示前 500 行。",
            "noAuthorizedForAnyOrg": "未找到授权活动组。请联系您的管理员",
            "noGoalTabInfo": "您没有归因数据，或尚未描述自定义转换。",
            "passwordTooShort": "密码太短，必须至少有 8 个字符",
            "pleaseEnterAnEmail": "请输入电子邮件",
            "pleaseEnterYourPassword": "请输入密码",
            "pleaseFillTheForm": "请填写表格，我们将尽快与您联系！",
            "restrictionLevelInfo": "<br><br> 例如，\"应用程序中的关键词\"；在本例中，限制级别为 \"应用程序\"：应用程序中的关键词；在本例中，限制级别为应用程序",
            "ruleStateInfo": "当前规则状态。",
            "selectCountryOrRegion": "请选择国家或地区",
            "selectMetrics": "选择要在图表中查看的指标",
            "signInWithApple": "使用 Apple 登录",
            "signInWithGoogle": "使用 Google 登录",
            "signInWithLinkedIn": "使用 LinkedIn 登录",
            "signUpWithYourEmail": "或使用您的电子邮件注册",
            "somethingHasGoneWrong": "哦，出错了！请稍后再试。",
            "unsupportedDomain": "您不能使用此域名注册。请使用企业电子邮件地址注册。",
            "thePageYouHaveRequested": "未找到您请求的页面。",
            "youCanAdjustYourBudget": "您希望在广告系列上花费的金额，也是初始广告系列设置的必经步骤之一。",
            "youCanSafelyCloseThisWindow": "您可以安全关闭此窗口，继续使用电子邮件中的链接。",
            "youCanSearchAndSelectAnApp": "你可以搜索并选择应用程序以查看活跃关键词点点击次数比率。",
            "youNeedTheCompleteOneMoreStep": "您还需要完成一个步骤才能继续。",
            "youWillNotBeChargedMoreThanYourCampaign": "您希望每天在广告系列上花费的最多金额。一旦达到每日预算，您的广告将在当天停止显示，但如果您的广告系列仍有剩余预算，则会在第二天重新开始。某一天的花费可能会略微超出每日预算。但是，您不会被收取超过广告系列预算的费用。",
            "yourAppWillBePromotedOnTheAppStore": "您的应用程序将在您从列表中选择的符合条件的国家和地区的 App Store 上进行推广。"
        },
        "integrateAccountView": {
            "noValidAccountFoundInfo": "看起来您的 Apple Search Ads 集成<span style=\"text-decoration: underline;\">已过期<\/span> 。要继续使用我们的平台，请将有效的 Apple Search Ads 账户连接到 SearchAds.com。",
            "accessDailyRunning": "按应用\/关键词轻松访问每日运行的广告。",
            "automateYourSearchAdsCampaign": "自动执行搜索广告活动",
            "connectYourSearchAds": "连接搜索广告账户",
            "connectYourSearchAdsAccount": "连接你的Search Ads帐户以开始使用",
            "createSearchAdsCampaigns": "创建搜索广告系列、广告组",
            "getAccessToDetailed": "获取所有活动的详细摘要",
            "getAccessToDetailedInsight": "了解不同国家的详细情况",
            "getAheadOfYourCompetitors": "使用市场上最智能的搜索广告工具，领先于您的竞争对手。",
            "getAnalyticsAbout": "分析您的营销活动如何影响您的排名",
            "searchDetailedInsight": "搜索关键词排名的详细洞察",
            "setAutomationsAndAlerts": "为您的搜索广告系列设置自动操作和警报",
            "setYourConditions": "设置条件、暂停营销活动、编辑出价并管理所有营销活动规格",
            "withAutomationsYouCan": "使用自动化功能，您可以",
            "withKeywordHunt": "通过关键词搜索，你可以：",
            "withSearchAdsYouCan": "使用搜索广告，您可以",
            "withYourConnectedAppleSearchAdsAccount": "通过已连接的Apple Search Ads账户，你可以：",
            "youGetAccessToOtherFeatures": "您还可以使用其他功能，如关键字拍卖洞察、自动化、有机关键字搜索。",
            "youWillGetAccessToOther": "您还可以使用其他功能，如关键词拍卖洞察、广告管理器。",
            "youWillGetAccessToOthers": "您还可以使用其他功能，如关键字拍卖洞察、仪表板、自动操作等。",
            "integrate": "整合",
            "noValidAccountFound": "您的集成已过期！"
        },
        "warnings": {
            "metricNotEligible": "该指标不符合 \"比较选项 \"的条件。",
            "singleCurrencyAutomation": "自动化规则仅适用于单一货币",
            "singleCurrencyAutomationDesc": "请删除带有二级货币的实体，以便继续创建自动化规则",
            "adGroupCustomError": "创建广告组失败，出错、{msg}",
            "adGroupUnrelatedGoalField": "您有与目标不相关的广告组过滤器。",
            "campaignDailyCapCanNotExceed": "活动每日预算不能超过活动预算，更正后再继续",
            "campaignIsUnderBudgetAllocation": "这项活动属于预算分配范畴。",
            "campaignUnrelatedGoalField": "您有与目标不相关的活动过滤器。",
            "dataIsNotFoundToSort": "未找到可排序的数据。数据可能正在加载或不可用。",
            "deviceTypeIPadCanNotBeSelected": "设备类型不能选择 IPad，只能选择 IPhone",
            "discardDraftSure": "如果丢弃此草稿，将无法挽回。",
            "duplicateAdGroupName": "广告组名称{msg} 在广告系列中不唯一。",
            "maxDataRange": "{level} 的最大日期范围可以是 180 天，因此会自动处理日期范围并获取{startDate} 和{endDate}",
            "mobileScreenWarning": "您可能会遇到手机视图的兼容性问题。我们强烈建议使用更舒适的屏幕。",
            "noInAppEventsYet": "尚无应用程序内活动",
            "noIntegratedApp": "无集成应用程序",
            "noKeywordRankingHistory": "无关键词排名历史记录{selectedKeywordString}",
            "notAvailableLocations": "如果您的营销活动设置为多个国家或地区，则不可用",
            "pleaseFixValidationErrors": "请修复页面上的所有验证错误后再继续",
            "pleaseSelectAGoalForFilter": "请选择一个目标以应用此过滤器",
            "pleaseSelectAdPlacement": "请选择广告位置",
            "pleaseSelectAnApp": "请选择一个应用程序",
            "pleaseSelectAnAttributonPartner": "请选择归属合作伙伴",
            "pleaseSelectAnGoalForFilter": "请选择一个目标以执行目标筛选",
            "promoteOthersAppDisallowed": "您的 Apple Search Ads 账户不允许推广其他应用程序！",
            "singleCurrencyWarning": "您的选择包括多种货币。您可以为单一货币创建自动化",
            "requestFailedDueTo": "请求失败的原因是{reason}",
            "usernameExistsGoLogin": "用户名已存在。点击此信息进入登录页面！",
            "warningOccurredKeywordAddition": "在尝试向<strong>({name})<\/strong> 添加关键字时出现警告。警告为<i>{warning}<\/i>",
            "withoutRelatedGoalAndRemoved": "由于当前的过滤器，目标已从过滤器中删除。",
            "youAreNotAuthorized": "您无权访问搜索广告。如果问题仍然存在，请联系我们"
        },
        "errors": {
            "accountHasReadOnlyAccess": "您没有所需的权限，请确保您的集成具有管理员权限。",
            "anErrorOccurredFetchingRules": "获取规则时发生错误：{msg}",
            "apiIntegration": "苹果搜索广告账户无效，您可以通过以下链接进行检查",
            "checkPermission": "请检查您的权限！",
            "dataIsTooBigToSort": "数据太大，无法排序。请过滤数据以进行排序。",
            "dataNotFound": "未找到数据。请从仪表板同步数据",
            "decimalFormatIsNotValid": "数字格式无效。小数分隔符为点（0.00）",
            "duplicateInputValidatorMessage": "值必须介于{min} 和{max}",
            "duplicatedKeywords": "重复的关键词",
            "errorOccurredKeywordAddition": "在尝试向<strong>({name})<\/strong> 添加关键字时发生错误。错误是<i>{error}<\/i>",
            "failedAdGroupReport": "加载广告组报告失败",
            "failedChangeState": "更改{level} 的状态失败：{msg}",
            "failedKeywordReport": "获取关键字报告失败",
            "failedToAdd": "添加{type} 失败：{msg}",
            "failedToAddKeywords": "添加关键字失败",
            "failedToCreateAdGroup": "创建广告组失败，错误信息为 \"{msg}\"",
            "failedToCreateAdGroupPlease": "创建广告组失败，请检查您的输入，如果问题仍然存在，请通知我们！",
            "failedToFetchAdGroup": "获取广告组失败",
            "failedToFetchCampaign": "获取活动失败{msg}",
            "fieldIsRequired": "该字段为必填字段",
            "fieldMustBeGreaterThan0": "该字段必须大于 0",
            "fixTheError": "修复错误",
            "invalidNumberFormat": "数字格式无效",
            "keywordLimitExceedForAdGroup": "广告组关键字超出限制，广告组关键字限制为 1000。确保列表和广告组中的关键词总数不超过限制后再试一次",
            "noActiveOrgSelected": "找不到活动组织。请刷新页面。",
            "noAnyOrgOnAccount": "我们无法在您的账户中找到任何活动组。请刷新页面或联系管理员。",
            "servicesError": "我们的服务出现错误。请检查您的输入",
            "somethingWentWrong": "UPS，出问题了。",
            "timeoutMessage": "看起来服务器响应时间过长，这可能是由于连接不畅或服务器出错造成的。请稍后再试",
            "thereAreKeywordsOnTheList": "列表中有一些关键词已经添加到了广告系列和广告组中",
            "unexpectedError": "出现意外错误，如果错误仍然存在，请联系我们。{msg}",
            "youAreNotAuthorizedToThisOperation": "您无权执行此操作"
        },
        "success": {
            "adCreativeUpdated": "广告更新成功",
            "adGroupBidUpdated": "最大 CPT 投标成功更新",
            "apiKeyCopied": "复制的 Api 密钥！",
            "cppLinkCopied": "复制自定义产品页面链接",
            "dailyBudgetUpdated": "成功更新每日预算",
            "keywordBidUpdated": "关键字出价更新成功",
            "keywordsAddedSuccessfully": "{n}-关键字已成功添加到<strong>({name})<\/strong> 。",
            "linkCopied": "复制链接",
            "operationCompleted": "操作成功完成。",
            "operationCompletedWith": "操作完成后会显示{success}-成功、{warning}-警告和{error}-错误。",
            "totalBudgetUpdated": "终身预算更新成功"
        },
        "noData": {
            "noKeywordAddedToCart": "购物车中尚未添加关键字",
            "previewNotAvailable": "预览不可用",
            "noDataAvailable": "无数据",
            "nothingToShow": "我们没有数据显示"
        }
    },
    "actionKit": {
        "competitorAddedSuccessfully": "成功添加竞争对手",
        "noMatchWithQuery": "未找到与您的查询匹配的内容",
        "showRecentlyViewed": "显示 @:common.recentlyViewed",
        "showSuggested": "显示 @:common.suggested",
        "typeAnAppName": "键入应用程序名称或出版商名称"
    },
    "page": {
        "adsManager": "广告管理",
        "automations": "自动化规则",
        "overview": "概述",
        "recommendations": "相关建议"
    },
    "tooltips": {
        "maxCPMBid": "<h3>最大 CPM 出价<\/h3>这是您愿意为 App Store 上每千次广告印象支付的最高金额。每当您的广告至少有 50% 在一秒钟内可见时，您的广告印象就会被计算在内。"
    }
}
