const intercom = (...args) => {
    if (!window.Intercom) {
        return null;
    }
    return window.Intercom(...args);
};

function IntercomPlugin() {
    IntercomPlugin.prototype.init = (name, user) => {
        intercom('boot', {
            name,
            email: user.username,
            created_at: user.createdAt,
            user_id: `${user.userId}`,
            user_hash: user.intercomUserHash,
        });
    };

    IntercomPlugin.prototype.logout = () => {
        intercom('shutdown');
    };

    IntercomPlugin.prototype.boot = (options) => {
        intercom('boot', options);
    };

    IntercomPlugin.prototype.show = () => {
        intercom('show');
    };

    IntercomPlugin.prototype.hide = () => {
        intercom('hide');
    };

    IntercomPlugin.prototype.update = (field) => {
        intercom('update', field);
    };

    //hide intercom button
    IntercomPlugin.prototype.hideLaunch = () => {
        intercom('update', {
            hide_default_launcher: true,
        });
    };

    //show intercom button
    IntercomPlugin.prototype.showLaunch = () => {
        intercom('update', {
            hide_default_launcher: false,
        });
    };

    //move intercom button to left
    IntercomPlugin.prototype.toLeft = () => {
        intercom('update', { alignment: 'left' });
    };

    //move intercom button to right
    IntercomPlugin.prototype.toRight = () => {
        intercom('update', { alignment: 'right' });
    };
}

export const MaIntercomPlugin = new IntercomPlugin();

export default function install(Vue) {
    Vue.config.globalProperties.$maIntercom = MaIntercomPlugin;
}
