{
    "ALL_USERS": "All Users",
    "ALL": "All",
    "ADDED": "Added",
    "NOT_ADDED": "Not Added",
    "ADDED_AND_EXCLUDED": "Added and Excluded",
    "EXCLUDED": "Excluded",
    "FEMALE": "Female",
    "IPAD": "iPad Only",
    "IPHONE": "iPhone Only",
    "IPHONE_IPAD": "iPad and iPhone",
    "MALE": "Male",
    "MALE_FEMALE": "All",
    "NEW_USERS": "New Users",
    "ONLY_RE_ATTR_TYPE": "Only Re-Attribution",
    "PAUSED_BY_USER": "Paused By User",
    "RETURNING_USERS": "Returning Users",
    "TOTAL_BUDGET_EXHAUSTED": "Lifetime Budget Exhausted",
    "USERS_OF_MY_OTHER_APPS": "Users of My Other Apps",
    "WITHOUT_RE_ATTR_TYPE": "Without Re-Attribution",
    "WITH_RE_ATTR_TYPE": "With Re-Attribution",
    "adCreativeRejected": "Ad creative Rejected",
    "actions": "Actions",
    "active": "Active",
    "adGroupStatus": "Ad Group Status",
    "addCreativeSets": "Add Creative Sets to make new ad variations",
    "addLabels": "Add Label(s)",
    "adName": "Ad Name",
    "addNegativeKeywords": "Add Negative Keywords to make new ad variations",
    "addSearchTermsToAppear": "Add Search Terms to appear for queries without adding keywords",
    "aiPoweredRecommendations": "AI-Powered Keyword Optimizer",
    "aiPoweredRecommendationsExp": "Automatically optimizes your Apple Search Ads performance using AI-driven best practices.",
    "aiPoweredRecommendationsUsage": "We recommend keeping this feature enabled for the best campaign results.",
    "allCampaigns": "All Campaigns",
    "appIcon": "App Icon",
    "appStoreProductPages": "App Store Product Pages",
    "appStoreSearchResults": "App Store Search Results",
    "appStoreSearchTab": "App Store Search Tab",
    "appStoreTodayTab": "App Store Today Tab",
    "automationRule": "Automation Rule",
    "automationFromAnotherAccount": "This automation is created from a different SearchAds account.",
    "bidKeywords": "Bid on Keywords to trigger and include your ad within relevant App Store search results",
    "buttons": {
        "automationRule": "Automation Rule",
        "addAutomationRule": "Add Automation Rule",
        "cancel": "Cancel",
        "compare": "Compare",
        "createNewCampaign": "Create New Campaign",
        "noCancel": "No, cancel",
        "rule": "Rule",
        "update": "Update",
        "yesContinue": "Yes, continue"
    },
    "callLatestData": "Call Latest Data",
    "campaignStatus": "Campaign Status",
    "cannotDuplicateCPM": "Some selected ad groups have CPM pricing model, these ad groups cannot be duplicated.",
    "cannotDuplicateMoreThanOneAccount": "Cannot duplicate more than 1 campaign group at a time",
    "categoryLabel": "{category} ({n} items)",
    "changeGoal": "Change Goal",
    "clear": "Clear",
    "clickToSeeRejectReasons": "Click to see reject reasons",
    "collapse": "Collapse",
    "compareGoal": "Select a goal to compare",
    "compareSelectedItems": "Compare Items",
    "compareSelectedItemsInfo": "Compare Selected Items by Chart",
    "compareSelectedMetadata": "Compare: {n} {type}",
    "copyAllKeywords": "Copy Keywords",
    "copyAllKeywordsToOther": "Copy any Ad Group Keywords to another Ad Group",
    "copyAllNegativeKeywords": "Copy All Negative Keywords",
    "copyAllNegativeKeywordsToOther": "Copy All Negative Keywords to other Ad Group",
    "copyKeywordsToOther": "Copy Selected Keywords to other Ad Group",
    "copyNegativeKeywordsToOther": "Copy Selected Negative Keywords to other Ad Group",
    "copySelectedCount": "No data | Copy selected search term {n} | Copy selected search terms {n}",
    "copySelectedKeywords": "Copy Selected Keywords",
    "copySelectedNegativeKeywords": "Copy selected negative keywords",
    "copySelectedSearchTerms": "Copy Selected Search Terms to other Ad Group as Keyword",
    "createAutomationRule": "Create Automation Rule",
    "createCampaigns": "Create Campaigns to run ads either in App Store search results or on the App Store Search tab",
    "creativeSetStatus": "Creative Set Status",
    "creativeTypes": {
        "CREATIVE_SET": "Creative Set",
        "CUSTOM_PRODUCT_PAGE": "Custom Product Page",
        "DEFAULT_PRODUCT_PAGE": "Default Product Page"
    },
    "dailyBudget": "Daily Budget",
    "dashboard": "Dashboard",
    "dashboardCurrency": "Dashboard currency: Your data will be served with selected currency.",
    "viewInAutomations": "View in Automations",
    "whoMadeUpdate": "Who made the update?",
    "bidAdjustmentMade": "The bid adjustment is made by your Customer Success Manager",
    "budgetAdjustmentMade": "The budget adjustment is made by your Customer Success Manager",
    "automationMadeUpdate": "Automation made the update",
    "clickToView": "Click to view",
    "deletedRule": "Deleted Automation Rule",
    "deletingAdsNotProvided": "Deleting Ads is not provided by Apple",
    "disabled": "Disabled",
    "downloadDailyData": "Download Daily Data",
    "downloadData": "Download Data",
    "downloadKeywords": "Download Keywords",
    "downloadSOVReport": "Download Share of Voice Report",
    "displayStatusColumn": {
        "ACTIVE": "Active",
        "AD_GROUP_ON_HOLD": "Ad group on hold",
        "CAMPAIGN_ON_HOLD": "Campaign on hold",
        "DELETED": "Deleted",
        "INVALID": "Invalid",
        "ON_HOLD": "On hold",
        "PAUSED": "Paused",
        "REMOVED": "Removed",
        "RUNNING": "Running"
    },
    "duplicateAccount": "Duplicate",
    "duplicateAdGroup": "Duplicate ad group",
    "duplicateCampaign": "Duplicate campaign",
    "duplicateCampaignWithAttributes": "Duplicate campaign with all attributes.",
    "duplicateCreativeSet": "Duplicate Creative Set",
    "editSelectedAdGroup": "Edit selected ad groups",
    "editSelectedCampaign": "Edit selected campaigns",
    "editSelectedCreativeSet": "Edit selected creative-sets",
    "expand": "Expand",
    "fileIsNotCorrectOrElse": "Data Import Error",
    "funnel": "Funnel",
    "goOnWithOld": "Go on with old",
    "goalInfo": {
        "eventName": "Events",
        "goalId": "Goal ID",
        "goalName": "Goal Name",
        "trackId": "App"
    },
    "goalSelection": "Goal Selection",
    "groupBy": "Group By",
    "issuesFound": "Issues Found",
    "keywordMatchTypeUpdated": "Keyword match type updated successfully",
    "labels": "Label(s)",
    "lastUpdateTime": "Last Update Time",
    "lowVolumeTerms": "(Low volume terms)",
    "ltvData": "LTV Data",
    "max20": "Max. 20",
    "maximizeWindow": "Maximize window",
    "messages": {
        "copyMain": {
            "keyword": "Keyword(s)",
            "negative": "Negative Keyword(s)",
            "term": "Search Term(s)"
        },
        "copySuccess": {
            "keyword": "Keyword(s) were copied to new ad group successfully.",
            "negative": "Negative Keyword(s) were copied to new ad group successfully.",
            "term": "Search Term(s) were copied to new ad group successfully."
        },
        "duplicatedKeyword": {
            "keyword": "There are Keyword(s) on the list that were already added to campaign and ad group",
            "negative": "There are Negative Keyword(s) on the list that were already added to campaign and ad group",
            "term": "There are Search Term(s) on the list that were already added to campaign and ad group"
        },
        "errors": {
            "adGroupBidUpdated": "Unexpected error occurred on max cpt bid update.",
            "checkPermission": "Please check your permissions!",
            "dailyBudgetUpdated": "Unexpected error occurred on daily budget update. {msg}",
            "dataDiscrepancyOrUnexpected": "Data Import Error",
            "errorType": {
                "adGroupCampaign": "ad groups of campaign",
                "campaign": "campaign",
                "creativeSet": "creative set",
                "negagativeKeywordsCampaign": "negative keywords of campaign",
                "negativeKeywords": "negative keywords",
                "targetedKeyword": "targeted keywords"
            },
            "failedCampaign": "Failed to change state of campaign: {msg}",
            "failedChangeState": "Failed to change state of {level}: {msg}",
            "failedDuplicate": "Failed to duplicate: {msg}, if it persists please contact us ",
            "failedDuplicatedError": "Failed to duplicate: {0}, with error {1}",
            "failedToFetchChart": "Time out was occurred when we try to fetch your chart data. Please click below button to try again.",
            "failedToFetchSummary": "Time out was occurred when we try to fetch your summary data. Please click below button to try again.",
            "failedToFetchTable": "Time out was occurred when we try to fetch your table data. Please click below button to try again.",
            "keywordBidUpdateFailed": "Unexpected error occurred on keyword bid update.",
            "noReportData": "Sorry! We couldn't find data to show.",
            "totalBudgetUpdated": "Unexpected error occurred on lifetime budget update. {msg}"
        },
        "info": {
            "addGoalTabInfo": "If you select this tab, you can describe new goal for your MMP Attribution data",
            "clickToFilter": "Click to filter according to this field",
            "dataIsFetching": "Latest data is been fetching. Please wait...",
            "deleteAdGroup": "Are you sure to delete selected adgroup(s)?",
            "deleteAds": "Are you sure you want to remove selected ad(s)?",
            "deleteCampaign": "Are you sure to delete selected campaign(s)?",
            "deleteCreativeSet": "Are you sure to delete selected creative set(s)?",
            "deleteNegativeKeyword": "Are you sure to delete selected negative keyword(s)?",
            "enterNewBidAmount": "Enter new bid amount",
            "goalTabInfo": "If you select this tab, you will see reporting data with the MMP Attribution data according to your predefined custom conversion.",
            "invalidKeywordBid": "Invalid keyword bid amount",
            "ltvNoFile": "You can upload your LTV Data by submitting Campaign ID, Campaign Name and LTV values in an excel respectively. Your edits will go live immediately after upload, so review carefully before submitting.",
            "ltvUploadError": "Please enter Campaign ID and LTV Data in numeric format. LTV Data column should not be empty.",
            "ltvUploadSuccessful": "LTV Data for {successfulCount} campaign(s) imported successfully.",
            "noGoalTabInfo": "You do not have attribution data or you have not describe custom conversion yet.",
            "noTodayDataYet": "We have not fetched your today's data yet. To fetch today's data, you can click \"Refresh\" button from top bar.",
            "oneAdGroup": "(One ad group at a time)",
            "oneCampaign": "(One campaign at a time)",
            "oneCreativeSet": "(One creative set at a time)",
            "refreshDataRestriction": "There is no data to fetch from Apple Search Ads.",
            "updateKeywordBids": "Update Keyword Bids of Selected Campaigns"
        },
        "success": {
            "adCreated": "Ad created successfully",
            "adGroupDuplicated": "Ad Group({msg}) duplicated successfully",
            "campaignDuplicated": "Campaign({msg}) duplicated successfully",
            "completedChangeState": "Change state operation completed successfully.",
            "completedChangeStateLater": "We got your change state request. This takes some time. When we finish it, we will notify you.",
            "completedDeleteItem": "Delete operation completed successfully.",
            "completedDeleteItemLater": "We got your delete request. This takes some time. When we finish it, we will notify you.",
            "deleteItem": "No campaigns deleted | Deleted {n} campaign | Deleted {n} campaigns",
            "keywordBidUpdated": "Keyword bid updated successfully",
            "keywordBids": "Keyword bid(s) of selected campaigns updated successfully",
            "latestDataCalled": "Request to refresh data is taken successfully. This takes more than 5 minutes..."
        },
        "warning": {
            "areYouSureToPause": "Are you sure to pause",
            "campaignsAreUnderBudgetAllocation": "The selected campaign(s) are under budget allocation so the Pause action may be overwritten. If you want to pause the campaig(s), we advise you to first stop the budget allocation and then take the pause action.",
            "defaultCreativeSetNotDuplicate": "Default Creative Sets cannot be duplicated.",
            "defaultCreativeSetNotEditable": "Default Creative Sets cannot be edited.",
            "defaultCreativeSetsIgnored": "You cannot duplicate default creative-sets so they are ignored automatically.",
            "fetchingCurrentData": "Fetching Current Data",
            "fetchingCurrentDataInfo": "You might not see all your current data.Now, we are fetching your data. When it is ready we will notify you.",
            "onlyOneSingleAdGroup": "You can only edit one single ad group, select one ad group",
            "onlyOneSingleCampaign": "You can only edit one single campaign, select one campaign",
            "onlyOneSingleCreativeSet": "You can only edit one single creative-set, select one creative-set",
            "onlySameAppsAtBulkDuplicate": "You can duplicate creative-sets from same applications on bulk-duplication.",
            "thereIsAlreadyACampaignName": "There is already a campaign named ({name}), change campaign name before continue"
        }
    },
    "metricNotAvailable": "This metric is not available at this level",
    "metricNotEventTime": "This metric does not apply to the event time view",
    "minimizeWindow": "Minimize window",
    "multiple": "Multiple",
    "nAdUpdated": "None ad updated | {n} Ad updated successfully | {n} Ads updated successfully",
    "nextSteps": {
        "title": "Next Steps",
        "header": "To address the custom product pages issue(s), follow these steps:",
        "step1": "Visit",
        "step2": "Address the identified issues for the custom product page you want to use for your Today tab ad.",
        "step3": "Resubmit your custom product page for approval in App Store Connect",
        "footer": "Once your custom product page is approved in App Store Connect, it'll be automatically resubmitted for approfal for your Today tab ad. If approved for advertising your ad will be eligible to run right away.",
        "haveQuestion": "Have a question ?",
        "contactUs": "Contact us"
    },
    "noAccounts": "No Campaign Groups to show",
    "noAdGroups": "No Ad Groups to show",
    "noAds": "No Ads to show",
    "noAdsRunningForSearchAds": "No Apps found running ads on Apple Search Ads",
    "noApps": "No Apps to show",
    "noAttribution": "No Attribution",
    "noAttributionInfo": "First of all, you should integrate your attribution to SearchAds Dashboard to go on with Goals",
    "noBudgetChange": "There is no budget change for this campaign",
    "noCampaigns": "No Campaigns to show",
    "noCreativeSets": "No Creative Sets to show",
    "noChange": "There is no change for this keyword",
    "noDataFoundForTimeGranularity": "No Data found for daily granularity",
    "noGoal": "No Goal",
    "noGoalInfo": "First of all, you should create goal to see your attribution data.",
    "noKeywords": "No Keywords to show",
    "noMatchingData": "No matching data with your filters, try clearing them!",
    "noNegativeKeywords": "No Negative Keywords to show",
    "noSearchTerms": "No Search Terms to show",
    "nonExistingRow": "You are trying to update ad groups inside nonexistent campaigns.",
    "notEventTakenLately": "Not event taken lately at least 30 days.",
    "notSelected": "Not Selected",
    "pendingIntegration": "We are waiting events from your attribution partner, this can take 24-48 hours.",
    "pendingIntegrationWarned": "There is still no install or in-app event occurred after the integration for this app for last 48 hours.",
    "pleaseChooseSingleGoal": "Please choose a single Goal to go on.",
    "reasonCode": {
        "APP_SCREENSHOTS_REQUIRED": {
            "description": "App screenshots required.",
            "title": "App Screenshots Required"
        },
        "EXCEEDS_CHARACTER_LIMIT": {
            "description": "Ad doesn’t comply with Apple Search Ads advertising requirements for exceeding character limit.",
            "title": "Exceeds Character Limit"
        },
        "AD_CONTENT_NOT_COMPLIANT": {
            "description": "Ad content not is not compliant with Apple Search Ads advertising requirements.",
            "title": "Ad Content Not Compliant"
        },
        "PRICING_OFFERS_OR_RANKING_CLAIMS": {
            "description": "No pricing, offers, ranking, or other incentivized promotions are allowed in the app subtitle.",
            "title": "Pricing Offers Or Ranking Claims"
        },
        "LANGUAGE_CONFLICT": {
            "description": "The language in the app name or subtitle must match the language selected in App Store Connect.",
            "title": "Language Conflict"
        },
        "GRAPHIC_OR_ADULT_THEMED_CONTENT": {
            "description": "Violent, offensive, sexually explicit, or otherwise inappropriate wording isn’t allowed in the app's title, subtitle or icon.",
            "title": "Graphic Or Adult Themed Content"
        },
        "NOT_COMPLIANT": {
            "description": "Not compliant",
            "title": "Not Compliant"
        },
        "INCORRECT_BEZEL": {
            "description": "Incorrect bezel",
            "title": "Incorrect Bezel"
        },
        "CONTROLLED_SUBSTANCES": {
            "description": "Controlled substances",
            "title": "Controlled Substances"
        },
        "POOR_IMAGE_QUALITY": {
            "description": "Poor image quality",
            "title": "Poor Image Quality"
        },
        "APP_ICON_GRAPHIC_OR_ADULT_THEMED_CONTENT": {
            "description": "Violent, offensive, sexually explicit, or other inappropriate images aren’t allowed in the app icon.",
            "title": "App Icon Graphic Or Adult Themed Content"
        },
        "APP_ICON_NOT_ELIGIBLE": {
            "description": "The app icon doesn’t comply with Apple Advertising guidelines.",
            "title": "App Icon Not Eligible"
        },
        "APP_NAME_LANGUAGE_CONFLICT": {
            "description": "The language in the app name must match the language selected in App Store Connect.",
            "title": "App Name Language Conflict"
        },
        "APP_NAME_GRAPHIC_OR_ADULT_THEMED_CONTENT": {
            "description": "Violent, offensive, sexually explicit, or otherwise inappropriate wording isn’t allowed in the app name.",
            "title": "App Name Graphic Or Adult Themed_Content"
        },
        "APP_NAME_NOT_ELIGIBLE": {
            "description": "The app name doesn’t comply with Apple Advertising guidelines.",
            "title": "App Name Not Eligible"
        },
        "APP_NOT_ELIGIBLE_AT_THIS_TIME": {
            "description": "The app isn’t eligible for promotion on the Today tab.",
            "title": "App Not Eligible At This Time"
        },
        "MIMICS_APP_STORE_TODAY_CARD": {
            "description": "The phrases Game of the Day and App of the Day can’t be used in the app icon, name, or subtitle.",
            "title": "Mimics App Store Today Card"
        },
        "PRODUCT_PAGE_OPTIMIZATION_EXPERIMENT_APP_ICON_NOT_ELIGIBLE": {
            "description": "The app icon from a product page optimization (PPO) experiment doesn’t comply with Apple Advertising guidelines.",
            "title": "Product Page Optimization Experiment App Icon Not Eligible"
        },
        "SUBTITLE_GRAPHIC_OR_ADULT_THEMED_CONTENT": {
            "description": "Violent, offensive, sexually explicit, or otherwise inappropriate wording isn’t allowed in the app subtitle.",
            "title": "Subtitle Graphic Or Adult Themed Content"
        },
        "SUBTITLE_LANGUAGE_CONFLICT": {
            "description": "The language in the app subtitle must match the language of the primary locale in App Store Connect.",
            "title": "Subtitle Language Conflict"
        },
        "SUBTITLE_NOT_ELIGIBLE": {
            "description": "The app’s subtitle doesn’t comply with Apple Search Ads advertising requirements.",
            "title": "Subtitle Not Eligible"
        },
        "SUBTITLE_PRICING_OFFERS_OR_RANKING_CLAIMS": {
            "description": "No pricing, offers, ranking, or other incentivized promotions are allowed in the app subtitle.",
            "title": "Subtitle Pricing Offers Or Ranking Claims"
        }
    },
    "reAttributionInfo": "<b>Re-Attribution</b><br>For metrics that are affected by Re-attribution data, you can choose whether calculation of the metric should be for only Re-attribution users or only newly attributed users or for both of the user types.",
    "result": {
        "fromNAccounts": "Results from {n} campaign groups",
        "fromNAdGroups": "Results from {n} ad groups",
        "fromNAds": "Results from {n} Ads",
        "fromNApps": "Results from {n} applications",
        "fromNCampaigns": "Results from {n} campaigns",
        "fromNCpps": "Results from {n} CPPs",
        "fromNCreativeSets": "Results from {n} creative sets",
        "fromNKeywords": "Results from {n} keywords",
        "fromNSearchTerms": "Results from {n} search terms"
    },
    "quickEdit": {
        "undefined": "Unknown error contact us to find out why.",
        "quickEditTitle": "Quick Edit",
        "allDataReviewMessage": "Be aware that this preview is based on current page data",
        "allDataReviewDescription": "This option will apply changes for all the items according to your selection.",
        "reviewChangesOf": "Review changes of",
        "oldValue": "Old Value",
        "newValue": "New Value",
        "attribute": "Attribute",
        "allValues": "All Values",
        "replaceWith": "Replace With",
        "selectOption": "Select Option",
        "action": "Action",
        "amount": "Amount",
        "maxLimit": "Max Limit",
        "use": "Use",
        "message": "Message",
        "status": "Status",
        "nameHint": "Use it to add/text macros before or after",
        "selectAtLeast": "Select at least 1 Campaign to use the Quick Edit",
        "macroError": "Invalid input. Please make sure all macros are used properly",
        "selectedItems": "Selected item(s)",
        "selectedCountries": "Select Countries",
        "DAILY_BUDGET": "Daily Budget",
        "COUNTRY_OR_REGION": "Country or Region",
        "NAME": "Name",
        "CPA_GOAL": "CPA Goal",
        "DEFAULT_MAX_BID": "Default Max Bid",
        "DEVICE": "Device",
        "GENDER": "Gender",
        "CUSTOMER_TYPES": "Customer Types",
        "AGE_RANGE": "Age Range",
        "IPHONE": "iPhone",
        "IPAD": "iPad",
        "AD_GROUP": "Ad Group(s)",
        "CAMPAIGN": "Campaign(s)",
        "SET": "Set to",
        "INCREASE": "Increase By",
        "DECREASE": "Decrease By",
        "ALL_USERS": "All Users",
        "NEW_USERS": "New Users",
        "RETURNING_USERS": "Returning Users",
        "OTHER_APP_USERS": "Users of my other apps",
        "COUNTRY_NOT_ELIGIBLE_IN_APP": "Countries are not eligible in app",
        "DAILY_BUDGET_TOO_LOW": "Daily Budget is too low",
        "DAILY_BUDGET_LOWER_THAN_MAX_AD_GROUPS_DEFAULT_BID_AMOUNT": "Daily Budget is lower than max Ad Groups Default bid amount",
        "DAILY_BUDGET_LOWER_THAN_MAX_AD_KEYWORDS_BID_AMOUNT": "Daily Budget is lower than max Ad Groups Default CPT Bid",
        "DAILY_BUDGET_LOWER_THAN_MAX_KEYWORDS_BID_AMOUNT": "Daily Budget is lower than max Keywords Bid Amount",
        "ACTION_NOT_TAKEN": "Actions are not taken",
        "CPA_NOT_AVAILABLE": "CPA value is not available",
        "DUPLICATE_CAMPAIGN_NAME": "There are already same named campaigns",
        "INVALID_FORMAT_CAMPAIGN_NAME": "Naming format is invalid",
        "USER_LIMIT_ACTION_NOT_TAKEN": "User limit for action are not taken",
        "FEMALE": "Female",
        "MALE": "Male",
        "ALL": "All",
        "campaignName": "Campaign's current name",
        "adGroupName": "Ad group's current name",
        "countries": "Storefronts",
        "deviceClass": "Device Type",
        "allMacros": "All Macros"
    },
    "scheduledReports": {
        "appliedData": "Applied Data",
        "reportingType": "Reporting Type",
        "dateRange": "Date Range",
        "selectedColumns": "Selected Columns",
        "runReport": "Run Report",
        "data": "Data",
        "schedule": "Schedule",
        "destination": "Destination",
        "runReportOnSpecificDaysAndTimes": "Run report on specific days & times",
        "enterYourEmail": "Enter your email",
        "runReportWithDateRange": "Run report with a date range",
        "couldNotFindLogs": "Could not find logs",
        "couldNotFindLogsWithFilters": "Could not find logs with your filters",
        "couldNotFindScheduledReports": "Could not find scheduled reports",
        "couldNotFindScheduledReportsWithFilters": "Could not find scheduled reports with your filters",
        "reportName": "Report Name",
        "createdBy": "Created By",
        "showLogs": "Show Logs",
        "manage": "Manage",
        "logs": "Logs",
        "successfullyCreated": "Your Scheduled Report is succesfully created!",
        "seeLogsAnyTime": "You can edit your report and see logs any time.",
        "successfullyUpdated": "Your Scheduled Report is successfully updated!",
        "successfullyStateUpdated": "State of Scheduled Report is succesfully updated!",
        "successfullyDeleted": "Scheduled Report is succesfully deleted!",
        "sendReportVia": "Send Report via",
        "deliveryFormat": "Delivery Format",
        "currentlyOnlyCsvSupported": "Currently, the Scheduled Report only supports CSV.",
        "reportNameHint": "This will also be used as the email subject.",
        "enterYourReportName": "Enter your report name",
        "selectedColumnsTooltip": "This section allows you to remove columns. Adding columns here is not possible. Please use Ads Manager to add columns.",
        "dateRangeTooltip": "Choose the date range to include data in this report.",
        "deletedCustomColumn": "Deleted custom column",
        "selectEmailNotifiers": "Please select at least one email notifier",
        "selectSlackChannel": "Please select at least one Slack channel",
        "reportNameRequired": "Please enter a report name",
        "selectedOptionTooltip": "This option will create a report just for the items you have selected.",
        "currentOptionTooltip": "This option will create a report just for the items on the displayed page.",
        "allOptionTooltip": "This option will create a report for all the items according to the current level and filters. Please, be aware that this operation might take some time.",
        "deletedCustomColumnTooltip": "This custom column is deleted so you cannot make any changes regarding that.",
        "warningCustomColumnTooltip": "This column will not be included in your report unless you select a Goal from the Ads Manager.",
        "1ReportFiltered": "1 report filtered",
        "editReportName": "Edit Report Name"
    },
    "searchCampaign": "Search campaign",
    "seeLess": "less",
    "seeMore": "more",
    "selectGoalInfo": "Click here to see your attribution data.",
    "selectOneForSOV": "Please select 1 campaign group to download Share of Voice Report",
    "selectedN": "{n} Selected",
    "servingReasons": {
        "ADGROUP_END_DATE_REACHED": "End date reached",
        "AD_APPROVAL_PENDING": "Ad creative pending",
        "AD_APPROVAL_REJECTED": "Ad creative rejected",
        "AD_GROUP_MISSING": "Ad Group Missing",
        "AD_GROUP_PAUSED_BY_USER": "Paused By User",
        "AD_PROCESSING_IN_PROGRESS": "Ad processing in progress",
        "APP_NOT_ELIGIBLE": "App not eligible",
        "APP_NOT_ELIGIBLE_SEARCHADS": "App not eligible for Searchads",
        "APP_NOT_LINKED_TO_CAMPAIGN_GROUP": "App not linked to Campaign Group",
        "APP_NOT_PUBLISHED_YET": "App not published yet",
        "APP_NOT_SUPPORT": "App not supported",
        "AUDIENCE_BELOW_THRESHOLD": "Audience below threshold",
        "BO_END_DATE_REACHED": "Budget order end date reached",
        "BO_EXHAUSTED": "Budget order exhausted",
        "BO_START_DATE_IN_FUTURE": "Budget order date not reached",
        "CAMPAIGN_END_DATE_REACHED": "End date reached",
        "CAMPAIGN_NOT_RUNNING": "Campaign not running",
        "CAMPAIGN_START_DATE_IN_FUTURE": "Start date not reached",
        "CONTENT_PROVIDER_UNLINKED": "Content Provider Unlinked",
        "CREATIVE_SET_INVALID": "Creative set invalid",
        "CREATIVE_SET_UNSUPPORTED": "Creative set unsupported",
        "CREDIT_CARD_DECLINED": "Credit Card Declined",
        "DAILY_CAP_EXHAUSTED": "Daily Budget Exhausted",
        "DELETED_BY_USER": "Deleted By User",
        "FEATURE_NOT_YET_AVAILABLE": "Not yet available",
        "FEATURE_NO_LONGER_AVAILABLE": "Feature no longer available",
        "LOC_EXHAUSTED": "Loc exhausted",
        "MISSING_BO_OR_INVOICING_FIELDS": "Missing Invoicing Fields",
        "NO_AVAILABLE_ADS": "No eligible ads available",
        "NO_AVAILABLE_AD_GROUPS": "No running ad groups",
        "NO_ELIGIBLE_COUNTRIES": "No eligible countries",
        "NO_PAYMENT_METHOD_ON_FILE": "No Payment Method",
        "ORG_CHARGE_BACK_DISPUTED": "Org charge back disputed",
        "ORG_PAYMENT_TYPE_CHANGED": "Payment type changed",
        "ORG_SUSPENDED_FRAUD": "Org suspended for fraud",
        "ORG_SUSPENDED_POLICY_VIOLATION": "Org suspended for policy violation",
        "PAUSED_BY_SYSTEM": "Paused by system",
        "PAUSED_BY_USER": "Paused By User",
        "PRODUCT_PAGE_DELETED": "Product page deleted",
        "PRODUCT_PAGE_HIDDEN": "Product page hidden",
        "PRODUCT_PAGE_INCOMPATIBLE": "Product page incompatible",
        "PRODUCT_PAGE_INSUFFICIENT_ASSETS": "Product page insufficient assets",
        "PENDING_AUDIENCE_VERIFICATION": "Pending Audience Verification",
        "SAPIN_LAW_AGENT_UNKNOWN": "Unknown Law agent",
        "SAPIN_LAW_FRENCH_BIZ": "Law French BIZ",
        "SAPIN_LAW_FRENCH_BIZ_UNKNOWN": "Unknown Law French BIZ",
        "START_DATE_IN_THE_FUTURE": "Start date not reached",
        "TAX_VERIFICATION_PENDING": "Tax verification pending",
        "TOTAL_BUDGET_EXHAUSTED": "Lifetime Budget Exhausted"
    },
    "settingsExcelExp": "A maximum of 500 edits can be made at once. Your edits will go live immediately after upload, so review carefully before submitting.",
    "showFunnel": "Show Funnel",
    "status": {
        "active": "Active",
        "invalid": "Invalid",
        "onHold": "On hold",
        "paused": "Paused",
        "running": "Running",
        "startDateNotReached": "Start date not reached"
    },
    "sovRankEnum": {
        "ONE": "Rank 1",
        "TWO": "Rank 2",
        "THREE": "Rank 3",
        "FOUR": "Rank 4",
        "GREATER_THAN_FIVE": "Rank > 5",
        "EMPTY": "Rank is N/A"
    },
    "tableColumn": {
        "NA": "N/A",
        "appName": "App Name",
        "campaign": "Campaign",
        "campaignId": "Campaign ID",
        "editColumn": "Edit Columns",
        "enabled": "Enabled",
        "activate": "Activate"
    },
    "totalBudget": "Lifetime Budget",
    "totalOfAllAccount": "Totals of All Campaign Groups",
    "totalOfAllAdGroups": "Totals of All Ad Groups",
    "totalOfAllApp": "Totals of All Apps",
    "totalOfAllCampaign": "Totals of All Campaigns",
    "totalValueCopied": "Total Value Copied",
    "trackKeywords": "Track Keyword(s)",
    "uploadData": "Upload Data",
    "updateKeywordsBids": "Update Keywords Bids",
    "updateKeywordsBidsSelected": "Update Keyword Bids of Selected Campaigns",
    "uploadKeywords": "Upload Keywords",
    "uploadNegativeKeywords": "Upload Negative Keywords",
    "uploadLTVData": "Upload LTV Data (XLSX File)",
    "useAdGroups": "Use Ad Groups to define who sees your ads",
    "viewReasons": "View Reasons"
}
