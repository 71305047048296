{
    "buttons": {
        "clearFilters": "クリアフィルター",
        "OK": "OK",
        "actions": "アクション",
        "adScheduling": "広告スケジュール",
        "add": "追加",
        "addAsKeyword": "キーワードとして追加",
        "addAsNegativeKeyword": "除外キーワードに追加",
        "addKeywords": "キーワードを追加",
        "addKeywordsToAdGroup": "広告グループにキーワードを追加",
        "addNegativeKeywords": "除外キーワードを追加",
        "addNew": "新規追加",
        "addSlackChannel": "Slackチャネルの追加",
        "adjust": "Adjust",
        "apply": "更新",
        "assignCategory": "カテゴリを付与",
        "audience": "オーディエンス",
        "automationCart": "Automation Cart",
        "automationRule": "自動化ルール",
        "back": "戻る",
        "cancel": "キャンセル",
        "change": "変更",
        "chart": "グラフ",
        "checkStatus": "ステータス確認",
        "choose": "選択",
        "clear": "クリア",
        "clearAll": "クリア",
        "close": "閉じる",
        "collapse": "縮小",
        "collapseAll": "表示を縮小",
        "confirm": "確認",
        "connectNow": "接続する",
        "contactSales": "サポートへ連絡",
        "continueToPreview": "プレビューに続く",
        "copy": "コピー",
        "copyUrl": "URLをコピー",
        "create": "作成する",
        "createAd": "広告の作成",
        "createAdGroup": "広告グループを作成",
        "createAnother": "続けて作成する",
        "createAutomationRule": "自動化ルールを作成",
        "createCampaign": "キャンペーンを作成",
        "createConversion": "コンバージョンを作成",
        "createCreativeSet": "Creative Setを作成",
        "createGoal": "目標イベントを作成",
        "createIntegration": "接続を追加",
        "createKeyword": "キーワードを追加",
        "createNegativeKeyword": "除外キーワードを追加",
        "createSearchTabAutomation": "Search tabキャンペーンの自動化ルールを作成",
        "delete": "削除",
        "discard": "破棄",
        "discardDraft": "下書きを破棄する",
        "dismiss": "無視する",
        "duplicate": "複製する",
        "done": "完了",
        "edit": "編集",
        "expand": "拡大する",
        "find": "探す",
        "goalSelection": "ゴール選択",
        "joinBeta": "ベータ版に参加する",
        "label": "ラベル",
        "learn": "学習する",
        "learnMore": "さらに詳しく",
        "linkItLater": "あとで接続する",
        "loadMore": "もっと読む",
        "logs": "履歴",
        "newTab": "新しいタブ",
        "next": "次へ",
        "openAutomationCart": "Open Automation Cart",
        "openInNewTab": "新しいブラウザのタブで開く",
        "refresh": "更新",
        "reloadPage": "今すぐリロード",
        "remove": "削除",
        "replay": "リプレイ",
        "save": "保存する",
        "saveAndClose": "保存して閉じる",
        "seeExample": "例を見る",
        "seeAll": "すべて見る",
        "send": "送る",
        "shareOn": "シェアする",
        "start": "スタート",
        "templates": "テンプレート",
        "tryAgain": "リトライ",
        "update": "更新",
        "hide": "隠す",
        "saveChanges": "変更を保存",
        "show": "もっと見る"
    },
    "columns": {
        "currency": "通貨",
        "CR": "CR (CR)",
        "TTR": "TTR",
        "action": "アクション",
        "actionType": "アクションタイプ",
        "active": "有効",
        "adChannel": "広告チャネル",
        "adGroupId": "広告グループID",
        "adGroupName": "広告グループ",
        "allAdGroups": "すべての広告グループ",
        "allCampaigns": "全てのキャンペーン",
        "allKeywords": "全てのキーワード",
        "appliedData": "対象",
        "automationLevel": "オートメーション・レベル",
        "averageCPA": "平均CPA",
        "averageCPT": "平均CPT",
        "avgCPA": "平均CPA (Apple)",
        "avgCPT": "平均CPT",
        "bidAmount": "入札額",
        "broadMatch": "部分一致",
        "budget": "予算",
        "campaignID": "キャンペーンID",
        "campaignName": "キャンペーン名",
        "campaignType": "キャンペーンタイプ",
        "category": "カテゴリー",
        "chance": "ASO難易度",
        "change": "変更",
        "clicks": "クリック",
        "conditions": "条件",
        "conversionRates": "CR (CR)",
        "conversions": "Conversions",
        "cpa": "CPA",
        "cpaGoal": "目標CPA",
        "cpm": "CPM",
        "cpt": "CPT",
        "cptBid": "CPT 入札額",
        "cr": "CR (CR)",
        "creativeSetId": "Creative Set ID",
        "dailyCap": "1日の上限",
        "dataCheckRange": "実績参照範囲",
        "dataComparisonRange": "データ比較期間",
        "dateOccured": "発生日",
        "defaultCPMBid": "デフォルトCPM入札額",
        "defaultCPTBid": "デフォルトのCPT入札額",
        "defaultMaxCpmBid": "デフォルトの最大CPM入札額",
        "defaultMaxCptBid": "デフォルトの最大CPT入札額",
        "difficultyRank": "オーガニック難易度",
        "email": "メールアドレス",
        "emailNotifier": "Eメール通知",
        "exactMatch": "完全一致",
        "excludeReAttribution": "リアトリビューションを除外する",
        "goal": "目標イベント",
        "goals": "目標イベント設定",
        "impressions": "インプレッション数",
        "includeReAttribution": "リアトリビューションを含む",
        "includedInGoal": "目標イベントに追加",
        "install": "インストール数",
        "installs": "インストール数",
        "invoicingOption": "請求オプション",
        "iphoneChance": "iPhone Chance",
        "keyword": "キーワード",
        "keywordId": "キーワードID",
        "keywords": "キーワード",
        "label": "ラベル",
        "lifetimeBudget": "生涯予算",
        "ltv": "LTV",
        "matchSource": "マッチ元",
        "matchType": "一致タイプ",
        "maxCPTBid": "最大CPT入札額",
        "maxCpmBid": "最大CPM入札額",
        "name": "ルール名",
        "negativeKeywords": "除外キーワード",
        "newBid": "新しい入札額",
        "newBudget": "新予算",
        "numberOfApps": "アプリの件数",
        "numberOfIphoneApps": "iPhoneアプリの件数",
        "oldBid": "元の入札額",
        "oldBudget": "旧予算",
        "onlyReAttribution": "リアトリビューションのみ",
        "organicRank": "オーガニック順位",
        "passive": "停止",
        "password": "パスワード",
        "percentage": "パーセンテージ",
        "popularity": "検索ボリューム",
        "popularityRank": "検索ボリューム",
        "queryType": "クエリ型",
        "reAttrType": "リアトリビューション設定",
        "reAttribution": "リアトリビューション",
        "reSend": "再送する",
        "recover": "再設定する",
        "redownloadsShare": "再ダウンロード比率",
        "restrictionEntityType": "クエリ型",
        "restrictionLevel": "制限レベル",
        "roas": "ROAS",
        "ruleActionFrequency": "アクション頻度",
        "ruleControlFrequency": "フリークエンシー制限",
        "ruleId": "ルール",
        "ruleLogState": "稼働状況",
        "ruleName": "ルール名",
        "ruleState": "ルール状態",
        "searchMatch": "検索マッチ",
        "searchTerm": "検索語句",
        "source": "ソース",
        "spend": "支出",
        "state": "稼働中",
        "status": "ステータス",
        "storefront": "国または地域",
        "taps": "タップ数",
        "toBeAdded": "追加予定",
        "ttr": "TTR",
        "updateTime": "更新時間",
        "withOutReAttribution": "新規のみ",
        "withReAttribution": "新規+リアトリビューション",
        "newDownloadsShare": "新ダウンロード比率",
        "emailNotEditable": "メールアドレス（編集不可）"
    },
    "device": {
        "IPHONE": "iPhone",
        "IPAD": "iPad",
        "IPHONE_AND_IPAD": "iPhoneおよびiPad"
    },
    "common": {
        "MALE": "男性",
        "FEMALE": "女性",
        "MALE_FEMALE": "男女",
        "wellBeBackSoon": "すぐに戻るよ！",
        "sorryForInconvenience1": "ご迷惑をおかけして申し訳ありませんが、現在メンテナンス中です。必要であれば、いつでも ",
        "sorryForInconvenience2": "そうでなければ、すぐにオンラインに戻ります！",
        "adsPerformances": "広告パフォーマンス",
        "adsPerformanceFor": "広告パフォーマンス{adGroup}",
        "adsTopFive": "グラフは、コンバージョン率に基づくパフォーマンス上位5広告を示しています。クリックすると",
        "adsToNavigate": "をクリックすると、この広告グループに接続されているすべての広告のパフォーマンスを確認できます。",
        "addLabelsFor": "ラベルの追加",
        "allData": "全データ",
        "action": "アクション",
        "alreadyInBudgetAllocationGroup": "このキャンペーンはすでに予算配分グループに入っているため、選択できません。",
        "alreadyInBudgetAllocationGroupAll": "{appName} 、すべてのキャンペーンはすでに予算配分に含まれている。",
        "bulkActions": {
            "CHANGE_BID": "キーワード入札の更新",
            "CHANGE_DEFAULT_CPC_BID": "デフォルトCPTビッドの更新",
            "CHANGE_DAILY_CAP": "日予算を変更",
            "CHANGE_CPA_GOAL": "CPA目標の変更",
            "ADD_LABEL": "ラベルの追加",
            "NEW_ACTION": "新しいアクション",
            "PAUSE": "停止"
        },
        "changeOrClearFilters": "フィルターを変更またはクリアしてください",
        "confirmDescription": "私は、このバルクアクションが{number} の項目に適用され、このアクションが不可逆的であることを確認する。",
        "cryptoCurrencies": "暗号通貨",
        "csv": "CSV",
        "currentView": "現在の見解",
        "emailPreview": "メールプレビュー",
        "entitySearchPlaceholder": {
            "AD_GROUP": "広告グループの検索",
            "CAMPAIGN": "キャンペーン検索",
            "KEYWORD": "キーワード検索",
            "NEGATIVE": "ネガティブ・キーワードの検索",
            "SEARCH_TERM": "検索語の検索"
        },
        "error": "エラー",
        "integrated": "接続完了",
        "link": "リンク",
        "showCumulative": "累積表示",
        "showValues": "バリューの表示",
        "myApps": "私のアプリ",
        "numberMetadataSelected": "{count} 個の{type}が選択されています",
        "noDataForFilter": "あなたのフィルターに一致するデータがない！",
        "nAdGroupLabelSelected": "広告グループラベルが選択されていない｜{n} 広告グループラベルが選択されている｜{n} 広告グループラベルが選択されている",
        "nCampaignLabelSelected": "キャンペーンラベル選択なし｜{n} キャンペーンラベル選択あり｜{n} キャンペーンラベル選択あり",
        "nKeywordLabelSelected": "キーワードラベル選択なし｜{n} キーワードラベル選択あり｜{n} キーワードラベル選択あり",
        "noActionLogDetails": "このアクションログの詳細はありません",
        "noAdsPerformace": "選択した時間帯に広告掲載なし",
        "noDataWithCurrentFilters": "現在のフィルターではデータが見つからない",
        "onlySelectedData": "選択されたデータのみ",
        "recentlyViewed": "最近閲覧した記事",
        "results": "結果",
        "searchNew": "検索...",
        "searchMatch": {
            "false": "オフ",
            "true": "オン"
        },
        "selectMetadata": "{type}を選択",
        "suggested": "推奨",
        "exclusive": "独占",
        "users": "ユーザー",
        "selectedOption": "このオプションは、選択した項目だけに変更を適用します。",
        "currentOption": "このオプションは、表示されているページの項目だけに変更を適用します。",
        "allOption": "このオプションは、現在のレベルとフィルターに従って、すべての項目に変更を適用します。この操作には時間がかかりますのでご注意ください。",
        "ADD_AS_KEYWORD": "キーワードとして追加",
        "ADD_AS_NEGATIVE_KEYWORD": "除外キーワードに追加",
        "ADD_LABEL": "ラベルの追加",
        "ALL": "全て",
        "AND": "AND",
        "APPLY_SMART_NOTIFICATION": "スマート通知を適用する",
        "APPLY_SMART_BID_SWEET_SPOT": "スマートビッド・スウィートスポットを適用",
        "CHANGE_BID": "入札を変更",
        "CHANGE_BUDGET": "予算を変更",
        "CHANGE_CPA_GOAL": "CPAキャップの変更",
        "CHANGE_DAILY_CAP": "日予算を変更",
        "CHANGE_TOTAL_BUDGET": "予算を変更",
        "CONTAINS": "内容",
        "DECREASE": "減少",
        "EMAIL_NOTIFICATION": "Eメール通知",
        "EMPTY": "該当なし",
        "GREATER_THAN": "指定した値より大きい(Greater Than)",
        "INCREASE": "指定した値を増やす",
        "IN_RANGE": "指定した値の範囲内",
        "LESS_THAN": "指定した値より低い(Less Than)",
        "LIMIT": "限度：",
        "NOT_CONTAINS": "含まれていない",
        "NOT_IN_RANGE": "指定した値の範囲外",
        "PAUSE": "停止",
        "SET": "に設定する。",
        "SET_TO_CPA": "平均CPAに変更",
        "SET_TO_CPT": "平均CPTに変更",
        "SLACK_NOTIFICATION": "Slack通知を送る",
        "UTC": "UTC",
        "accessRequired": "要アクセス",
        "accountIntegrationsExpired": "{orgName} に有効な統合がありません。そのアカウントに有効な統合を提供するか、お問い合わせください。今すぐ統合するには、この警告をクリックしてください！",
        "accounts": "キャンペーングループ",
        "add": "追加",
        "actionFrequency": "アクション頻度",
        "activate": "有効化",
        "activeOrganization": "活動的な組織：{org}",
        "adGroup": "広告グループ",
        "adGroupSettings": "広告グループ設定",
        "adGroupStatus": "広告グループのステータス",
        "adGroups": "広告グループ",
        "adPerformance": "広告成果",
        "adPlacement": "広告表示箇所",
        "adPlacements": {
            "APPSTORE_PRODUCT_PAGES_BROWSE": "製品ページ--閲覧中",
            "APPSTORE_SEARCH_RESULTS": "検索結果",
            "APPSTORE_SEARCH_TAB": "検索タブ",
            "APPSTORE_TODAY_TAB": "今日のタブ"
        },
        "ads": "広告",
        "adsStructure": "広告階層",
        "ageRange": "年齢の範囲",
        "all": "全体",
        "alwaysTranslate": "キーワードの英訳を常に表示",
        "allUsers": "全てのユーザー",
        "amount": "量",
        "and": "および",
        "apiKey": "APIキー",
        "app": "アプリ",
        "appName": "アプリ名",
        "apps": "アプリ",
        "areaChart": "エリアチャート",
        "attribution": "計測パートナー",
        "audience": "オーディエンス",
        "automationEntityList": "自動化ルール対象リスト",
        "available": "利用可能",
        "basic": "ベーシック",
        "beta": "ベータ",
        "between": "指定した値の範囲内 (Between)",
        "bidAmountHistory": "入札額履歴",
        "bidSources": {
            "DASHBOARD": "ダッシュボード",
            "RULE": "オートメーション",
            "EXTERNAL": "外部",
            "SMART_BID": "全自動入札調整",
            "BUDGET_ALLOCATION": "予算配分",
            "QUICK_AUTOMATION": "クイック・オートメーション",
            "AI_KEYWORD_OPTIMIZER": "AIによるキーワード最適化"
        },
        "budgetOrder": "予算オーダー",
        "bulkUpdate": "一括更新",
        "campaign": "キャンペーン",
        "campaignSetting": "キャンペーン設定",
        "campaignStatus": "キャンペーン状況",
        "campaigns": "キャンペーン",
        "categories": {
            "BRAND": "#ブランド",
            "CATEGORY": "#カテゴリ",
            "COMPETITOR": "#競合",
            "DISCOVERY": "#探索",
            "UNASSIGNED": "#未選択",
            "description": {
                "AGE": "もし年齢や性別などで広告配信の範囲を絞っている場合、同じくAppleはLAT（追跡型広告制限）がONになっているユーザーも配信から除外します。",
                "BRAND": "ブランドキャンペーン：広告対象のアプリ名や社名を検索するユーザーに注力",
                "BROAD": "部分一致では、広告出稿の対象に、単数形、複数形、スペルミス、同義語、対象の語句を（一部または全部）含む関連検索や関連フレーズなど、キーワードの類似パターンが含められます。",
                "COMPETITOR": "競合キャンペーン：広告対象の類似アプリ名やカテゴリ内の競合名を検索するユーザーに注力",
                "DISCOVERY": "探索キャンペーン：アプリに関連する新しい検索語句を発見することに注力",
                "EXACT": "検索されるキーワードをより細かく絞りたい場合、完全一致を用います。",
                "GENDER": "もし年齢や性別などで広告配信の範囲を絞っている場合、同じくAppleはLAT（追跡型広告制限）がONになっているユーザーも配信から除外します。",
                "GENERIC": "一般キャンペーン：広告対象となるアプリのカテゴリやアプリ内容等に関連する、ブランド以外の流入ユーザーに注力",
                "LAT": "もし年齢や性別などで広告配信の範囲を絞っている場合、同じくAppleはLAT（追跡型広告制限）がONになっているユーザーも配信から除外します。"
            }
        },
        "changeContentFromSidebar": "左のサイドバーからコンテンツを変更できます",
        "chart": "グラフ",
        "chartType": "チャートタイプ",
        "chartFilters": {
            "customColumnNotApplicable": "カスタム・カラムは日足チャート・ビューにのみ適用できる。",
            "monthlyChartNotAvailable": "カスタム列フィールドが選択されると、月次チャートオプションは利用できない。",
            "weeklyChartNotAvailable": "カスタム列フィールドが選択されると、週足チャートのオプションは利用できない。"
        },
        "cohortAnalysis": "コホート集計",
        "columnChart": "棒グラフ",
        "columns": "コラム",
        "comingSoon": "Coming Soon",
        "computed": "(加工済)",
        "confirm": "確認",
        "contactUsTerm1": "お問い合わせ",
        "contactUsTerm2": "ご質問・不明点があればサポートまでご連絡ください",
        "contains": "内容",
        "copy": "コピー",
        "countriesAndRegions": "国または地域",
        "country": "国",
        "countryCodes": "国コード",
        "cpp": "CPP",
        "createdAt": "作成日時",
        "creativeSetSettings": "Creative Set設定",
        "creativeSets": "Creative Sets",
        "currency": "通貨",
        "currencyCode": "コード ({code})",
        "currencySymbol": "通貨({currency})",
        "currencyView": "通貨ビュー",
        "customColumns": "カスタムカラム",
        "customConversion": "カスタムコンバージョン",
        "customerType": "お客様のタイプ",
        "daily": "24時間毎",
        "dailyBudget": "1日の上限",
        "dailyBudgetHistory": "日々の予算履歴",
        "dashboard": "ダッシュボード",
        "dataRange": "実績参照範囲",
        "defaultColumns": "デフォルトのカラム",
        "delete": "削除",
        "devices": "デバイス",
        "displayStatus": "ステータスを確認",
        "download": "ダウンロード",
        "downloadCsv": "CSVをダウンロード",
        "dontTranslate": "キーワードの英訳を表示しない",
        "duplicate": "複製する",
        "editColumns": "カラムを編集",
        "enable": "有効化",
        "enabled": "有効",
        "endDate": "終了日",
        "enterNewBindAmount": "新しい入札額を入力",
        "equals": "イコール",
        "events": "イベント",
        "failed": "失敗",
        "filter": "フィルター",
        "filters": "フィルタ",
        "for": "にとって",
        "formula": "数式",
        "frequency": "ルール稼働周期",
        "from": "送付元",
        "gender": "性別",
        "goal": "目標イベント",
        "greaterThan": "指定した値より大きい(Greater Than)",
        "here": "こちら",
        "hideChart": "チャートを隠す",
        "hourly": "1時間毎",
        "in": "範囲内(In)",
        "inAppConversions": "アプリ内コンバージョン",
        "inAppEventsSettings": "アプリ内イベント設定",
        "inQueue": "待機中",
        "info": "情報",
        "insights": "インサイト",
        "integratedOrgs": "接続されたキャンペーングループ",
        "integration": "接続",
        "integrationId": "統合ID：",
        "integrationStatus": "接続ステータス",
        "integrations": {
            "partners": {
                "adjust": "Adjust",
                "airbridge": "AirBridge",
                "appsflyer": "AppsFlyer",
                "branch": "Branch",
                "custom": "カスタム",
                "kochava": "Kochava",
                "singular": "Singular",
                "tenjin": "Tenjin"
            }
        },
        "is": "は",
        "isNot": "ではない",
        "keywordStatus": "キーワード稼働状況",
        "keywords": "キーワード",
        "language": "言語",
        "lessThan": "指定した値より低い(Less Than)",
        "splineChart": "スプラインチャート",
        "lineChart": "線グラフ",
        "loading": "読み込み中...",
        "logs": "履歴",
        "makeSelection": "選択してください",
        "message": "メッセージ",
        "monthly": "毎月",
        "more": "もっと見る",
        "multiLoginDetected": "他のブラウザからのログインが検出されました。ログインページへリダイレクトされます。",
        "multiple": "複数",
        "nAccount": "キャンペーングループなし｜{n} キャンペーングループ｜{n} キャンペーングループ",
        "nAccountsSelected": "キャンペーングループが選択されていない｜{n} キャンペーングループが選択されている｜{n} キャンペーングループが選択されている",
        "nAdGroup": "広告グループが未選択 | {n}個の広告グループ | {n}個の広告グループ",
        "nAdGroupsSelected": "広告グループが未選択 | {n}個の広告グループ | {n}個の広告グループ",
        "nApp": "アプリ無し | {n}個のアプリ | {n}個のアプリ",
        "nAppsSelected": "アプリが未選択 | {n}個のアプリ | {n}個のアプリ",
        "nAdPlacementSelected": "広告出稿なしを選択｜{n} 広告出稿ありを選択｜{n} 広告出稿ありを選択",
        "nLabelSelected": "ラベル選択なし｜{n} ラベル選択あり｜{n} ラベル選択あり",
        "nStatusSelected": "ステータスが選択されていない｜{n} ステータスが選択されている｜{n} ステータスが選択されている",
        "nAddedAsKeywordSelected": "選択したキーワードとして追加されない｜{n} 選択したキーワードとして追加される｜{n} 選択したキーワードとして追加される",
        "nDeviceClassSelected": "デバイスなし｜{n} デバイス｜{n} デバイス",
        "nGenderSelected": "性別なし｜{n} 性別｜{n} 性別",
        "nCustomerTypeSelected": "顧客タイプなし｜{n} 顧客タイプ｜{n} 顧客タイプ",
        "nBidStrengthSelected": "入札の強さなし｜{n} 入札の強さ｜{n} 入札の強さ",
        "nCreativeTypeSelected": "クリエイティブ・タイプなし｜{n} クリエイティブ・タイプ｜{n} クリエイティブ・タイプ",
        "nCampaign": "キャンペーンが未選択 | {n}個のキャンペーン | {n}個のキャンペーン",
        "nCampaignsSelected": "キャンペーンが未選択 | {n}個のキャンペーン | {n}個のキャンペーン",
        "nCountriesSelected": "国が未選択 | {n}個の国 | {n}個の国",
        "nCountry": "国がありません | {n}個の国 | {n}個の国",
        "nCreativeSet": "Creative Setがありません | {n}個のCreative Set | {n}個のCreative Set",
        "nGoal": "目標イベントなし | {n}個の目標イベント | {n}個の目標イベント",
        "nKeyword": "キーワードなし | {n}個のキーワード | {n}個のキーワード",
        "nKeywordsSelected": "キーワードが未選択 | {n}個のキーワード | {n}個のキーワード",
        "nCPPSelected": "CPP 選択なし｜{n} CPP 選択あり｜{n} CPP 選択あり",
        "nRulesSelected": "ルールが未選択 | {n} 個のルール | {n} 個のルール",
        "negativeKeywords": "除外キーワード",
        "new": "New",
        "newFeature": "新機能がリリースされました！",
        "newUsers": "新規ユーザー",
        "no": "いいえ",
        "noBidChangeInRange": "選択した時間帯では入札変更はありません",
        "noBudgetChangeInRange": "選択した時間範囲において、1日の予算に変化はない",
        "noData": "データがありません",
        "noFiltersApplied": "フィルタが適用されていません",
        "noGoalSelection": "目標イベントが未選択",
        "noIntegration": "未接続",
        "noIntegrationAdded": "接続が未完了です",
        "notBetween": "指定した値の範囲外 (Not Between)",
        "notContains": "含まない",
        "notEquals": "イコールではない(≠)",
        "notScheduled": "予定なし",
        "onHold": "保留",
        "operation": "作業",
        "optional": "任意",
        "or": "もしくは",
        "organization": "キャンペーングループ",
        "organizations": "キャンペーングループ",
        "passed": "合格",
        "pause": "停止",
        "paused": "停止",
        "preparing": "準備中",
        "preview": "プレビュー",
        "processing": "処理中",
        "processingLevel": "{level} を処理中...",
        "promoText": "プロモ・テキスト",
        "promotedApp": "プロモーションアプリ",
        "ranking": "ランキング",
        "reAttrType": "リアトリビューション設定",
        "reason": "理由",
        "report": "レポート",
        "reportingNote": "レポートは最大で過去24時間分が遅延する場合があります。",
        "resolved": "解決済み",
        "requestTime": "リクエスト時間",
        "retry": "リトライ",
        "returningUsers": "復帰ユーザー",
        "rules": "ルール",
        "running": "稼働中",
        "savedFilters": "保存したフィルタ",
        "scheduleAds": "広告スケジュール",
        "scheduled": "予定",
        "search": "検索",
        "searchApp": "アプリを検索",
        "searchByMetric": "指標を検索",
        "searchByName": "名前で検索",
        "searchByNameOrId": "名前・IDで検索",
        "searchKeyword": "キーワードを検索",
        "searchTerms": "検索語句",
        "select": "選択",
        "selectACampaign": "キャンペーンを選択",
        "selectAnAdGroup": "広告グループを選択",
        "selectCampaign": "キャンペーンを選択",
        "selectCategory": "カテゴリを選択",
        "selectGoalInfo": "クリックして計測パートナーの数値を表示",
        "sessionHasExpired": "セッションが期限切れです。ログインページにリダイレクトされます。",
        "settings": "設定",
        "showChart": "チャートを表示",
        "showSteps": "手順をみる",
        "smartBidding": "全自動入札調整",
        "standardInvoice": "スタンダード請求書",
        "startDate": "開始日",
        "status": "ステータス",
        "stop": "停止",
        "stopped": "停止中",
        "successful": "成功",
        "switchCurrencyView": "クリックして通貨表示を変更",
        "templates": "テンプレート",
        "timeZone": "タイムゾーン",
        "timezoneNote": "タイムゾーン設定",
        "total": "合計",
        "totalCount": "合計 {total}",
        "totalSummary": "支出サマリ",
        "type": "タイプ",
        "translationOn": "翻訳について",
        "translationOff": "翻訳オフ",
        "update": "更新",
        "updated": "更新",
        "updateKeywordBidAmount": "キーワード入札を更新",
        "upload": "アップロード",
        "uploadedIntegrations": "接続をアップロードする",
        "usersOfMyOtherApps": "自社の別アプリのユーザー",
        "view": "表示",
        "warning": "警告",
        "weekly": "毎週",
        "yes": "はい",
        "youWillBeRedirected": "{autoRedirectTimer} 秒後にリダイレクトします",
        "contactTeamAdministrator": "チーム管理者にお問い合わせください。",
        "noSearchAdsAuthorization": "あなたはSearchAds.com by MobileActionを閲覧する権限がありません。",
        "statuses": {
            "pending": "ペンディング"
        },
        "enterAValidEmail": "有効なメールアドレスを入力してください",
        "exports": "データ出力"
    },
    "components": {
        "dateRangeSelector": {
            "last14Days": "過去14日間",
            "custom": "カスタム",
            "last2Weeks": "過去2週間",
            "last30Days": "過去30日間",
            "last3Mths": "過去3ヶ月",
            "last6Mths": "過去6ヶ月",
            "last7Days": "過去1週間",
            "lastMonth": "先月",
            "lastWeek": "先週",
            "lastYear": "過去1年",
            "pickARange": "範囲を選択",
            "thisMonth": "今月",
            "thisWeek": "今週",
            "today": "今日",
            "week": "週",
            "yesterday": "昨日"
        },
        "maColumnsManager": {
            "cannotMakePrivate": "あなたが所有していないプライベートプリセットを作成することはできません。",
            "upgradeForCustomColumns": "より多くのカスタム・カラムを作成するには、アップグレードが必要です。",
            "upgradeForCohortColumns": "Cohort Metricsを適用するにはアップグレードが必要です。",
            "clearAll": "クリア",
            "dragDrop": "ドラッグ & ドロップで順番変更",
            "duplicatePresetName": "プリセット名が既に使用されています。別の名前をご使用ください。",
            "editColumnsFor": "カラムを編集： {table}",
            "makePrivate": "プライベート",
            "makePublic": "公開する",
            "nColumnsSelected": "{n} 個のカラムが選択されています",
            "privatePreset": "プライベート・プリセット",
            "privatePresets": "プライベート・プリセット",
            "publicPreset": "パブリック・プリセット",
            "publicPresets": "パブリック・プリセット",
            "saveAsPreset": "プリセットに保存",
            "searchByMetric": "指標を検索",
            "selectAll": "全て選択"
        },
        "updateChecker": {
            "importantUpdateReleased": "重要なアップデートがリリース",
            "newVersionAvailable": "新バージョン",
            "newVersionAvailableRefresh": "SearchAds.comダッシュボードの新バージョンをご利用いただけます。バッジをクリックすると、ダッシュボードが最新の機能に更新されます。",
            "newVersionAvailableRefreshMajor": "SearchAds.comダッシュボードの新バージョンを公開しました。以下のボタンで今すぐ更新するか、60秒後に更新されるまでお待ちください。",
            "pageWillRefreshIn": "アップグレードのためにページが更新されます：",
            "refreshNow": "今すぐリフレッシュ",
            "refreshLater": "後でリフレッシュします",
            "willRefreshIn": "ウィル・リフレッシュ・イン "
        },
        "appSearch": {
            "searchFailedWithErrorCode": "失敗しました:",
            "searchForAnyAppByName": "アプリ名もしくはアプリIDで検索"
        },
        "campaignGroup": {
            "chooseCampaignGroup": "キャンペーングループを選択",
            "selectAnIntegration": "接続アカウントを選択"
        },
        "common": {
            "apply": "更新",
            "columnsToHide": "表示しない列",
            "columnsToShow": "表示する列",
            "resetToDefaults": "デフォルトにリセット",
            "saveAsPreset": "プリセットに保存"
        },
        "countrySelector": {
            "selectAStoreFront": "国または地域を選択"
        },
        "dataLevels": {
            "ACCOUNT": "キャンペーングループ",
            "AD": "広告",
            "AD_GROUP": "広告グループ",
            "APP": "アプリ",
            "CAMPAIGN": "キャンペーン",
            "CPP": "CPP",
            "KEYWORD": "キーワード",
            "NEGATIVE": "除外キーワード",
            "SEARCH_TERM": "検索語句",
            "CREATIVE_SET": "Creative Set"
        },
        "goals": {
            "categories": {
                "ENGAGEMENT": "エンゲージメント",
                "GAME_EVENT": "ゲームイベント",
                "OTHER": "その他",
                "PURCHASE": "購入",
                "SUBSCRIPTION": "サブスクリプション",
                "TRIAL": "トライアル"
            }
        },
        "keywordAddition": {
            "BROAD": "部分一致",
            "EXACT": "完全一致",
            "keywordType": "タイプ",
            "negative": "除外"
        },
        "loadingScreen": {
            "loadingSearchAds": "Search Ads by Mobile Action | 読み込み中..."
        },
        "saAuthHeader": {
            "adBlockersAreKnowToBlock": "アドブロッカーは広告という文言が含まれるものに対して機能するため、表示項目が一部機能しなくなる場合があります。この問題を避けるため、本サイトにおいてはアドブロッカーを停止してください。",
            "ifYouAreUsingAdBlock": "アドブロッカーを使用している場合、表示項目改善のためにオフにしてください。"
        }
    },
    "messages": {
        "info": {
            "signInWithAppleSearchAds": "Apple検索広告でサインインする",
            "toScrollUseShift": "表の右側にスクロールするには、「Shift + マウススクロール」を使用します。",
            "actionTypeInfo": "別途作成されたルールに基づいてアクションが設定されました",
            "adBlockersIsNotEnabled": "アドブロッカーが無効になっていることをお確かめください。問題が継続する場合、サポートまでご連絡ください。",
            "addGoalTabInfo": "このタブを選択すると、目標イベントを切り替えて表示させることができます。目標イベントは「目標イベント設定」のメニューから作成可能です。",
            "addingKeywordsToAdGroup": "キーワードを広告グループに追加しています...",
            "addingNegativeKeywordsToAdGroup": "除外キーワードを広告グループに追加しています...",
            "addingNegativeKeywordsToCampaign": "除外キーワードをキャンペーンに追加しています...",
            "almostThere": "もう少しで完了します",
            "amountMustBeGreaterThan0": "0以上の値を入力してください",
            "areYouSureDeleted": "選択した項目を削除しますか？",
            "automationLevelInfo": "<br><br> 例：アプリのキーワード：この例では、オートメーションレベルはKeywordsである。",
            "budgetCanNotBeEmpty": "予算が空欄です",
            "budgetValueIsNotValid": "予算の値が無効です",
            "campaignNameCanNotBeEmpty": "キャンペーン名は空欄にすることができません",
            "campaignNameShouldBeUniqueForOrg": "同じキャンペーン名はアカウント内で複数使用することができません",
            "chooseOneOrMoreCountries": "1つ以上の国または地域を選択してください",
            "clickToDetail": "クリックして詳細を確認",
            "clickToUpdate": "クリックして更新",
            "continueWithSSO": "SSOを続ける",
            "copyReportLink": "レポートリンクをコピーする",
            "createAccount": "キャンペーングループの作成",
            "creatingAdCreative": "広告クリエイティブの作成...",
            "creatingAdGroup": "広告グループを作成しています...",
            "creatingCampaign": "キャンペーンを作成しています...",
            "creatingCreativeSet": "Creative Setを作成しています...",
            "dailyBudgetCanNotBeEmpty": "一日の予算を空にすることはできない",
            "dailyBudgetExplanation": "日予算とは、1ヶ月の間に毎日キャンペーンに費やす平均額です。月間予算は、1日予算×1ヶ月の平均日数を超えることはありません。<br><br> キャンペーンは、一時停止、削除、または終了日を設定しない限り、1日の予算額に基づいて毎月支出し続けます。終了日を設定した場合、キャンペーンの総日数×一日予算以上の支出はありません。<br><br> 一日予算はいつでも調整できます。",
            "dataIsFetching": "データ取得中...",
            "dataProvidedBy": "Data provided by",
            "doNotHaveAnAccountYet": "アカウントをまだお持ちではありませんか？",
            "enterABillingContactEmail": "お支払いご担当者のメールアドレスを入力してください",
            "enterABuyerEmail": "購入ご担当者のメールアドレスを入力してください",
            "enterABuyerName": "購入ご担当者のお名前を入力してください",
            "enterAClientName": "クライアント名を入力してください",
            "enterAOrderNumber": "予算オーダー番号を入力してください",
            "enterAValidBuyerEmail": "有効な購入者メールアドレスを入力してください",
            "enterAValidContactEmail": "有効な連絡先メールアドレスを入力してください",
            "enterAValidEmail": "有効なメールアドレスを入力してください",
            "goalTabInfo": "このタブを選択すると、目標イベントを切り替えて表示させることができます。目標イベントは「目標イベント設定」のメニューから作成可能です。",
            "haveToReloadPage": "このページの読み込み中にコンテンツが更新されました。このページを更新するか、10秒後に再読み込みされます。",
            "inputValidNumber": "有効な数値を入力してください",
            "lifetimeBudgetExplanation": "生涯予算は、キャンペーンに使用する合計金額です。<br><br> キャンペーンを継続的に実施し、オポチュニティの高い時間帯を利用して支出を最適化したい場合は、いつでも日額予算のみに切り替えることができます。一度切り替えると、同じキャンペーンにライフタイム予算を再度使用することはできません。",
            "loadingDataFromServer": "サーバーからデータを読み込んでいます...",
            "max500Row": "*冒頭の500行が表示されています",
            "noAuthorizedForAnyOrg": "有効なキャンペーングループが見つかりませんでした。再度読み込みを試していただくか、管理者までご連絡ください。",
            "noGoalTabInfo": "計測パートナー数値が転送されていないか、目標イベントが設定されていません",
            "passwordTooShort": "パスワードが短すぎます。8文字以上で設定してください。",
            "pleaseEnterAnEmail": "メールアドレスを入力してください",
            "pleaseEnterYourPassword": "パスワードを入力してください",
            "pleaseFillTheForm": "フォームに内容をご入力してください。サポートからご連絡させていただきます。",
            "restrictionLevelInfo": "<br><br> 例：アプリのキーワード：この例では、制限レベルはAppsである。",
            "ruleStateInfo": "ルールのステータス",
            "selectCountryOrRegion": "国または地域を選択してください",
            "selectMetrics": "チャートで表示するメトリクスを選択",
            "signInWithApple": "Appleでサインイン",
            "signInWithGoogle": "Googleログイン",
            "signInWithLinkedIn": "LinkedInログイン",
            "signUpWithYourEmail": "もしくはメールアドレスで登録",
            "somethingHasGoneWrong": "不明なエラーが検出されました。時間をおいてリトライしてください。",
            "unsupportedDomain": "このドメインでは登録できません。ビジネス用のメールアドレスでご登録ください。",
            "thePageYouHaveRequested": "リクエストされたページが見つかりませんでした。",
            "youCanAdjustYourBudget": "予算はキャンペーンに投下したい金額であり、初回のキャンペーン設定で必須の項目になります。予算はいつでも変更することができます。",
            "youCanSafelyCloseThisWindow": "こちらのウィンドウは閉じていただいて構いません。送信されているメール内のリンクから再開してください。",
            "youCanSearchAndSelectAnApp": "アプリを検索して選択すると、出稿中キーワードのインプレッション比率が確認できます",
            "youNeedTheCompleteOneMoreStep": "あと１つのステップで完了します。",
            "youWillNotBeChargedMoreThanYourCampaign": "キャンペーンに使用する1日あたりの予算です。1日あたりの予算に達すると、その日の広告は表示されなくなりますが、キャンペーンの予算が残っている場合は翌日から再開されます。一日の広告費が予算を若干上回る場合もございます。しかし、キャンペーン予算以上の費用が請求されることはありません。",
            "yourAppWillBePromotedOnTheAppStore": "選択した国または地域においてアプリの広告を開始することができます。"
        },
        "integrateAccountView": {
            "noValidAccountFoundInfo": "Apple Search Ads の統合が<span style=\"text-decoration: underline;\">期限切れ<\/span> になっているようです。引き続き弊社プラットフォームをご利用いただくには、有効なApple Search AdsアカウントをSearchAds.comに接続してください。",
            "accessDailyRunning": "出稿中アプリのキーワード情報が簡単に取得できます",
            "automateYourSearchAdsCampaign": "キャンペーンを自動化する",
            "connectYourSearchAds": "Apple Search Adsのアカウントに接続する",
            "connectYourSearchAdsAccount": "Apple Search Adsのアカウントを接続してください",
            "createSearchAdsCampaigns": "キャンペーン・広告グループを作成",
            "getAccessToDetailed": "全キャンペーンの詳細なサマリを確認",
            "getAccessToDetailedInsight": "国別の詳細なインサイトへアクセス",
            "getAheadOfYourCompetitors": "最良のキャンペーン管理ツールで競合アプリに差をつけましょう",
            "getAnalyticsAbout": "あなたの広告がオーガニックランキングに与えている影響を確認",
            "searchDetailedInsight": "キーワード順位から示唆を得る",
            "setAutomationsAndAlerts": "自動化ルールを作成し、キャンペーン状況のアラートを受け取る",
            "setYourConditions": "条件設定の上、キーワードの入札変更、停止など詳細なキャンペーンのコントロール",
            "withAutomationsYouCan": "自動化ルールを設定すると、以下のことが可能になります",
            "withKeywordHunt": "オーガニックワード検索では、以下のことが可能になります",
            "withSearchAdsYouCan": "以下のことが可能になります",
            "withYourConnectedAppleSearchAdsAccount": "Apple Search Adsのアカウントを接続すると、以下のことが可能になります",
            "youGetAccessToOtherFeatures": "他社の出稿中キーワード・オーガニックキーワードの発見、自動化ルールの設定",
            "youWillGetAccessToOther": "他社の出稿中キーワード・オーガニックキーワードの発見、管理画面の設定",
            "youWillGetAccessToOthers": "他社の出稿中キーワード・オーガニックキーワードの発見、管理画面の設定、自動化ルール",
            "integrate": "接続",
            "noValidAccountFound": "API接続が期限切れです"
        },
        "warnings": {
            "metricNotEligible": "この指標は比較オプションの対象外である。",
            "singleCurrencyAutomation": "自動化ルールは単一通貨にのみ適用される",
            "singleCurrencyAutomationDesc": "オートメーション・ルールの作成に進むには、セカンダリー・カレンシーのあるエンティティを削除してください。",
            "adGroupCustomError": "広告グループの作成に失敗しました、{msg}",
            "adGroupUnrelatedGoalField": "選択されていた目標イベントに関連しない広告グループのフィルタを解除しました",
            "campaignDailyCapCanNotExceed": "キャンペーンの1日の上限はキャンペーン予算を上回ることができません。続行するには修正してください。",
            "campaignIsUnderBudgetAllocation": "このキャンペーンは予算配分の下にある。",
            "campaignUnrelatedGoalField": "選択されていた目標イベントに関連しないキャンペーンのフィルタを解除しました",
            "dataIsNotFoundToSort": "データが見つからず並び替えできません。無効なデータもしくは読み込み中です。",
            "deviceTypeIPadCanNotBeSelected": "広告グループ名 {msg} はキャンペーン内で既に存在しています。",
            "discardDraftSure": "下書きは保存されません",
            "duplicateAdGroupName": "広告グループ名「{msg}」は既に存在しています",
            "maxDataRange": "{level}の日付参照範囲は最大180日です。現在ご覧になっているデータは自動で{startDate} 〜 {endDate}に設定されています。",
            "mobileScreenWarning": "モバイルの画角では見づらい可能性があります。より広いスクリーンでの表示を推奨します。",
            "noInAppEventsYet": "アプリ内イベントはまだ受信されていません",
            "noIntegratedApp": "アプリが接続されていません",
            "noKeywordRankingHistory": "このキーワードでのランキング履歴が見つかりませんでした {selectedKeywordString}",
            "notAvailableLocations": "複数の国や地域が設定されていない場合は無効です",
            "pleaseFixValidationErrors": "続行する前に、ページ上のすべてのバリデーションエラーを修正してください。",
            "pleaseSelectAGoalForFilter": "フィルタに適用する目標イベントを選択してください",
            "pleaseSelectAdPlacement": "広告のプレースメントを選択してください",
            "pleaseSelectAnApp": "アプリを選択してください",
            "pleaseSelectAnAttributonPartner": "アトリビューション・パートナーを選択してください。",
            "pleaseSelectAnGoalForFilter": "目標イベントを選択してください",
            "promoteOthersAppDisallowed": "あなたのApple Search Adsアカウントは他者のアプリに出稿する許可が得られていません",
            "singleCurrencyWarning": "選択した内容には複数の通貨が含まれています。自動化ルールは単一の通貨のみで作成することができます。",
            "requestFailedDueTo": "リクエストは以下の理由で失敗しました：{reason}",
            "usernameExistsGoLogin": "ユーザー名が既存です。クリックしてログインページに移動してください。",
            "warningOccurredKeywordAddition": "<strong>({name})<\/strong>にキーワードを追加する際に注意項目が発生しました：<i>{warning}<\/i>",
            "withoutRelatedGoalAndRemoved": "現在のフィルタに切り替える際に目標イベントの選択が解除されました",
            "youAreNotAuthorized": "アクセスが承認されていません。この事象が継続する場合、サポートまでご連絡ください"
        },
        "errors": {
            "accountHasReadOnlyAccess": "作業に必要な権限がないようです。API接続が管理者権限になっているか今一度ご確認ください。",
            "anErrorOccurredFetchingRules": "ルール読み込みにエラーが発生しました: {msg}",
            "apiIntegration": "Apple Search Adsのアカウントが無効です。",
            "checkPermission": "パーミッションをご確認ください",
            "dataIsTooBigToSort": "データ量が多すぎて並び替えできません。データを絞り込んでください。",
            "dataNotFound": "データが見つかりませんでした。管理画面を再度同期してください。",
            "decimalFormatIsNotValid": "数値形式が有効ではありません。小数点はドット(0.00)を用いてください",
            "duplicateInputValidatorMessage": "値は {min} ~ {max} の範囲内である必要があります",
            "duplicatedKeywords": "キーワード重複",
            "errorOccurredKeywordAddition": "キーワード追加時にエラーが発生しました。追加先： <strong>({name})<\/strong> エラー内容： <i>{error}<\/i>",
            "failedAdGroupReport": "広告グループのレポート表示に失敗しました",
            "failedChangeState": "{level}のステータス変更に失敗しました: {msg}",
            "failedKeywordReport": "キーワードレポートの読み込みに失敗しました",
            "failedToAdd": "追加に失敗しました {type} : {msg}",
            "failedToAddKeywords": "キーワードの追加に失敗しました",
            "failedToCreateAdGroup": "広告グループの作成に失敗しました {msg}",
            "failedToCreateAdGroupPlease": "広告グループの作成に失敗しました。入力内容をご確認いただき、問題が継続するようであればサポートまでご連絡ください。",
            "failedToFetchAdGroup": "広告グループの読み込みに失敗しました",
            "failedToFetchCampaign": "キャンペーンの読み込みに失敗しました {msg}",
            "fieldIsRequired": "必須項目です",
            "fieldMustBeGreaterThan0": "0より大きい値を設定する必要があります",
            "fixTheError": "エラーを修正",
            "invalidNumberFormat": "無効な数値形式です",
            "keywordLimitExceedForAdGroup": "キーワードの制限を超えました。広告グループには1000個までのキーワードしか登録できません。キーワード数の合計をご確認いただきリトライしてください。",
            "noActiveOrgSelected": "有効なアカウントが見つかりませんでした。ページを再度読み込んでください。",
            "noAnyOrgOnAccount": "あなたのアカウントにキャンペーングループが見つかりませんでした。ページを更新するか、管理者に連絡してください。",
            "servicesError": "エラーが発生しました。入力内容をご確認ください。",
            "somethingWentWrong": "不明なエラーが検出されました。",
            "timeoutMessage": "サーバーが応答するのに時間がかかっているようです。これは接続が悪いか、サーバーのエラーが原因です。しばらくしてからもう一度お試しください。",
            "thereAreKeywordsOnTheList": "対象のキャンペーン・広告グループに既に追加されたキーワードがあります",
            "unexpectedError": "不明なエラーです。解決しない場合、サポートまでご連絡ください {msg}",
            "youAreNotAuthorizedToThisOperation": "お客様はこの作業に関する権限がありません"
        },
        "success": {
            "adCreativeUpdated": "広告の更新に成功",
            "adGroupBidUpdated": "最大CPT入札額は正常に更新されました",
            "apiKeyCopied": "APIキーをクリップボードにコピーしました",
            "cppLinkCopied": "カスタム製品ページのリンクをコピー",
            "dailyBudgetUpdated": "日次予算の更新に成功",
            "keywordBidUpdated": "キーワード入札の更新時に不明なエラーが発生しました",
            "keywordsAddedSuccessfully": "{n}個のキーワードが <strong>({name})<\/strong> に追加されました",
            "linkCopied": "コピーされたリンク",
            "operationCompleted": "正常に完了しました。",
            "operationCompletedWith": "処理が完了しました： {success}-成功, {warning}-注意 and {error}-エラー.",
            "totalBudgetUpdated": "生涯予算の更新に成功"
        },
        "noData": {
            "noKeywordAddedToCart": "まだカートにキーワードが追加されていない",
            "previewNotAvailable": "プレビューが利用できません",
            "noDataAvailable": "データがありません",
            "nothingToShow": "表示可能なデータがありません"
        }
    },
    "actionKit": {
        "competitorAddedSuccessfully": "競争相手の追加に成功",
        "noMatchWithQuery": "クエリと一致するものが見つかりません",
        "showRecentlyViewed": "表示 @:common.recentlyViewed",
        "showSuggested": "@:common.suggested を表示",
        "typeAnAppName": "アプリ名またはパブリッシャー名を入力"
    },
    "page": {
        "adsManager": "管理画面",
        "automations": "自動化ルール",
        "overview": "オーバービュー",
        "recommendations": "レコメンド"
    },
    "tooltips": {
        "maxCPMBid": "<h3>最大CPM入札<\/h3>App Storeでの広告インプレッション1,000回あたりの支払い可能な上限額です。インプレッションは、広告の50％以上が1秒間表示されるたびにカウントされます。"
    }
}
