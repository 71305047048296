import devDefaultEnv from './dev';
import prodDefaultEnv from './prod';


export const environment = import.meta.env.MODE;
export const isProduction = environment === 'production';
export const isDevelopment = environment === 'development';
export const isStaging = environment === 'staging';
export const version = import.meta.env.VITE_VERSION;
export const DEBUG_SESSION_EXPIRED = `${import.meta.env.VITE_DEBUG_SESSION_EXPIRED || ''}`.toLowerCase() === 'true';
export const GOOGLE_RECAPTCHA_SITE_KEY = import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY || '6LeNVGclAAAAAH8fbWQj0ukS9zqSRSYkkflzl1Ya';
export const PUBLIC_API_KEY = import.meta.env.VITE_PUBLIC_API_KEY || 'mapublicsite3005';
export const  SENTRY_ORG_SLUG = 'mobileaction';
export const  SENTRY_DSN = 'https://9758cb97dc507e64698ef1859e0b4b1a@o4504038070681600.ingest.us.sentry.io/4506943433474048';

// override env with process environment
const env = isProduction ? prodDefaultEnv : devDefaultEnv;
window.env = env;

export const {
    PUBLIC_SITE_DOMAIN,
    GATEWAY_DOMAIN,
    INSIGHTS_DOMAIN,
    CPP_INTEL_URL,
    STRIPE_PUBLISHABLE_KEY,
    REQUEST_DEMO_URL,
    SEARCHADS_PUBLIC_SITE_DOMAIN,
    INTERCOM_APP_ID,
    SENTRY_SAMPLE_RATE,
    APP_VERSION,
    MAINTENANCE_MODE,
    SB_LIMIT_REMOVED_ACCOUNTS,
    SEGMENT_WRITE_KEY,
} = env;
