<template>
    <div class="flex items-center justify-between p-2">
        <div class="ma-caption-1-medium text-gray-900">
            {{ selectAllCheckboxLabel || t('allUsers') }}
        </div>
        <template v-if="!hideAllSelect">
            <ma-link-button
                v-if="!allSelected"
                size="small"
                color="blue"
                @click="selectAll"
            >
                {{ t('selectAll') }}
            </ma-link-button>
            <ma-link-button
                v-else
                size="small"
                color="blue"
                @click="deselectAll"
            >
                {{ t('buttons.clear') }}
            </ma-link-button>
        </template>
    </div>
    <div v-if="showSearch" class="p-2">
        <ma-input
            v-model:value="searchText"
            size="small"
            prefix-icon="search-normal"
            :placeholder="t('searchLabel')"
            class="ma-borderless [&_.ma-icon]:text-gray-400"
            allow-clear
        />
    </div>
    <div class="ma-selector-popover--checkbox-group-content">
        <ma-checkbox-group
            v-if="labelSelector"
            :value="value"
            class="ma-selector-popover--checkbox-group"
            @update:value="v => emit('update:value', v)"
        >
            <ma-checkbox
                v-for="option in options"
                v-show="isSearched(option)"
                :key="option.value"
                :value="option.value"
            >
                <slot name="label" v-bind="option">
                    {{ option.label || options.name || option.value }}
                </slot>
            </ma-checkbox>
        </ma-checkbox-group>
        <ma-checkbox-group
            v-else
            :value="value"
            :options="filteredOptions"
            class="ma-selector-popover--checkbox-group"
            @update:value="v => emit('update:value', v)"
        >
            <template #label="slotProps">
                <slot name="label" v-bind="slotProps"/>
            </template>
        </ma-checkbox-group>
    </div>
</template>

<script setup>
    import { MaLinkButton, MaCheckboxGroup, MaInput, MaCheckbox } from '@mobileaction/action-kit';
    import { computed, ref } from 'vue';
    import i18n from '@/pages/Automations/Components/AutomationComponents.i18n';
    import { useI18n } from 'vue-i18n';

    defineOptions({ i18n });

    const { t } = useI18n();

    const props = defineProps({
        value: { type: Array, required: true },
        options: { type: Array, required: true },
        selectAllCheckboxLabel: { type: String, default: null },
        hideAllSelect: { type: Boolean },
        showSearch: { type: Boolean },
        labelSelector: { type: Boolean, default: false },
    });
    const emit = defineEmits(['update:value']);

    const searchText = ref('');
    const allSelected = computed(() =>{
        return (props.options.length && props.options.every(opt => props.value.includes(opt.value || opt)));
    });

    const filteredOptions = computed(() => {
        if (!props.showSearch || !searchText.value) {
            return props.options;
        }
        return props.options.filter((o) => {
            const label = String(o.label || o.name || o.value).toLowerCase();
            const search = searchText.value.toLowerCase();
            return label.includes(search);
        });
    });
    const isSearched = (option) => {
        if (!searchText.value) {
            return true;
        }
        return String(option.label || option.name || option.value).toLowerCase().includes(searchText.value.toLowerCase());
    };

    const selectAll = () => {
        emit('update:value', props.options.map(c => c.value || c));
    };
    const deselectAll = () => {
        emit('update:value', []);
    };
</script>

<style lang="less">
.ma-selector-popover--checkbox-group-content {
    @apply ~'max-h-[400px]' overflow-y-auto;
    .ma-selector-popover--checkbox-group {
        @apply flex flex-col gap-1;
        .antd-checkbox-wrapper {
            @apply p-2 rounded-lg w-full;
            &.antd-checkbox-wrapper-checked:not(.antd-checkbox-wrapper-disabled) {
                @apply bg-blue-25 font-medium;
            }
            &.antd-checkbox-wrapper-disabled {
                @apply text-gray-400;
            }
            &:hover {
                @apply bg-gray-50;
            }
            span:not(.antd-checkbox) {
                @apply block flex-grow;
            }

            .antd-checkbox-checked.antd-checkbox-disabled .antd-checkbox-inner {
                @apply !bg-gray-100;
            }
        }
    }
}
.ma-select-all-checkbox.antd-checkbox-wrapper {
    @apply font-semibold mb-1 p-2 rounded-lg w-full;
    &.antd-checkbox-wrapper-checked {
        @apply bg-blue-25;
    }
    &:hover {
        @apply bg-gray-50;
    }
}
</style>
