import { defineStore } from 'pinia';
import { getUserApps } from '@/common/MaRequests/User';
import { safeTrackIdEquals } from '@/common/MaUtils.mjs';
import { ref, computed, watch } from 'vue';

import dayjs from '@/plugins/dayjs.mjs';
import { getSpendSummary, recentEvents, conversions } from '@/common/MaRequests/Attribution';
import { isNoData, ISO_DATE_FORMAT } from '@/common/MaUtils.mjs';
import { fetchAppsWithCompetitors } from '@/common/MaRequests/AppCompetitor';
import { ACCESS_TYPES, validOrgList } from '@/common/FilteringUtils';
import CurrencySymbolMap from 'currency-symbol-map';
import { USER_ACCESS_TYPE } from '@/pages/Settings/composables/useTeamMemberOptions';
import { useGlobal } from '@/composables/index';


export const useAccountStore = defineStore('account', () => {
    const { $maLocalStorage, $maSessionStorage, $log } = useGlobal();

    const integrations = ref($maLocalStorage.integrations || {});
    const userOrgs = ref([]);
    const goals = ref([]);
    const allGoals = ref([]);
    const ruleTemplates = ref([]);
    const attributions = ref({});
    const activeOrg = ref($maLocalStorage.activeOrg);
    const accountTotalSpend = ref([]);
    const currencyView = ref($maLocalStorage.currencyView);
    const attrCandidates = ref([]);
    const activeCurrency = ref($maLocalStorage.activeOrg?.currency);
    const menuCollapsed = ref($maSessionStorage.menuCollapsed);
    const fetched = ref({ goals: false, orgs: false });
    const appCompetitors = ref([]);
    const appCompetitorsLoading = ref(false);
    const appInfoFetched = ref(false);
    const userAccessType = ref(null);



    const allOrgs = ref([]);
    const loading = ref(false);

    const goalsIdMap = computed(() => {
        const map = {};
        goals.value.forEach(g => map[g.id] = { ...g });
        return map;
    });
    const appGoalsMap = computed(() => {
        const map = {};
        goals.value.forEach((g) => {
            if (!map[g.trackId]) {
                map[g.trackId] = [g];
            } else {
                map[g.trackId].push(g);
            }
        });
        return map;
    });
    const ruleTemplatesComputed = computed(() => ruleTemplates.value || []);
    const attributionsComputed = computed(() => attributions.value || {});
    const appCompetitorsComputed = computed(() => appCompetitors.value || {});
    const appCompetitorsLoadingComputed = computed(() => appCompetitorsLoading.value);
    const currency = computed(() => activeOrg.value?.currency || 'USD');
    const currencySymbol = computed(() => {
        if (currencyView.value === 'code') {
            return activeCurrency.value;
        }
        return CurrencySymbolMap(activeCurrency.value);
    });
    const attrCandidatesComputed = computed(() => attrCandidates.value || []);
    const currencies = computed(() => {
        const currencySet = new Set();
        if (integrations.value) {
            Object.keys(integrations.value).flatMap(k => integrations.value[k])
                .filter(k => k.valid).forEach((o) => {
                    currencySet.add(o.currency);
                });
        }
        return [...currencySet].map(c => ({ code: c, symbol: CurrencySymbolMap(c) }));
    });
    const isUserAccessLimited = computed(() => userAccessType.value === ACCESS_TYPES.LIMITED);
    const isUserNoAccess = computed(() => userAccessType.value === USER_ACCESS_TYPE.NO_ACCESS);
    const accountTotalSpendComputed = computed(() => accountTotalSpend.value);
    const noValidIntegrations = computed(() => Object.values(integrations.value || {}).every(orgs => orgs.every(org => !org.valid)));


    const hasValidOrganization = computed(() => allOrgs.value?.length > 0);

    const appIdList = computed(() => {
        const apps = allOrgs.value.flatMap(d => d.apps);
        return [...new Set(apps.map(d => Number(d.trackId)))];
    });

    const apps = computed(() => {
        const apps = allOrgs.value.flatMap(d => d.apps);
        return appIdList.value.map(trackId => ({
            ...apps.find(app => safeTrackIdEquals(app.trackId, trackId)),
        }));
    });

    const appInfoMapping = computed(() => {
        return apps.value.reduce((res, app) => (res[app.trackId] = app, res), {});
    });

    const appIdOrgIdsMapping = computed(() => {
        return appIdList.value.reduce((res, trackId) => {
            const orgs = allOrgs.value
                .map(({ apps, orgId }) => {
                    return apps?.some(app => safeTrackIdEquals(trackId, app.trackId))
                        ? orgId
                        : null;
                })
                .filter(org => org);
            return {
                ...res,
                [trackId]: orgs,
            };
        }, {});
    });

    const orgIdList = computed(() => {
        return [...new Set(orgList.value.map(d => d.orgId))];
    });

    const orgIdMapping = computed(() => {
        return allOrgs.value.reduce((result, org) => (result[org.orgId] = org, result), {});
    });

    const orgIdAppMapping = computed(() => {
        return allOrgs.value.reduce((result, org) => (result[org.orgId] = org.apps, result), {});
    });


    const appIdOrgMapping = computed(() => {
        return allOrgs.value.reduce((result, org) => {
            org.apps.forEach((app) => {
                if (!result[app.trackId]) {
                    result[app.trackId] = [];
                }
                result[app.trackId].push(org.orgId);
            });
            return result;
        }, {});
    });

    const orgList = computed(() => {
        return allOrgs.value.map(({ orgId, orgName, currency }) => ({
            orgId, orgName, currency,
        }));
    });


    const fetchAttributionEvents = (retry) => {
        recentEvents()
            .then((data) => {
                attributions.value = data[0];
            })
            .catch((e) => {
                attributions.value = {};
                if (isNoData(e)) {
                    return;
                }
                if (retry) {
                    setTimeout(() => {
                        fetchAttributionEvents(false);
                    }, 30000);
                }
                console.error('Failed fetch event list from account-store', e);
            });
    };

    const fetchAccountTotalSpend = () => {
        const startDate = dayjs().add(-30, 'days').format(ISO_DATE_FORMAT);
        const endDate = dayjs().format(ISO_DATE_FORMAT);
        let orgList = [];
        Object.keys(integrations.value || {}).forEach((k) => {
            integrations.value[k].forEach((i) => {
                orgList.push(i.orgId);
            });
        });
        if (isUserAccessLimited.value) {
            orgList = userOrgs.value.map(org => org.orgId);
        }
        getSpendSummary(startDate, endDate, orgList)
            .then((data) => {
                let totals = [];
                if (data && data.length) {
                    totals = data[0].totalExpense;
                }
                accountTotalSpend.value = totals;
            })
            .catch((e) => {
                accountTotalSpend.value = [];
                const { errors } = e;
                if (errors && errors.includes('NOT_LOGGED_IN')) {
                    return;
                }
                $log.error('Failed to fetch account spend summary', e);
            });
    };

    const getAppsCompetitors = () => {
        appCompetitorsLoading.value = true;
        fetchAppsWithCompetitors()
            .then((data) => {
                appCompetitors.value = data;
                appCompetitorsLoading.value = false;
            })
            .catch((e) => {
                appCompetitors.value = [];
                appCompetitorsLoading.value = false;
                if (isNoData(e)) {
                    return;
                }
                $log.error('Failed to fetch app competitors', e);
            });
    };

    const getAttributions = () => {
        fetchAttributionEvents(true);
    };

    const getGoals = () => {
        conversions()
            .then((data) => {
                goals.value = data;
            })
            .catch((e) => {
                goals.value = [];
                if (isNoData(e)) {
                    return;
                }
                $log.error('Failed to fetch conversions', e);
            });
    };

    const toggleCurrencyView = () => {
        const view = currencyView.value === 'symbol' ? 'code' : 'symbol';
        $maLocalStorage.currencyView = view;
        currencyView.value = view;
    };

    const updateIntegrations = (integrationsData) => {
        if (!integrationsData || !Object.keys(integrationsData).length) {
            integrations.value = null;
            activeOrg.value = null;
        } else {
            integrations.value = integrationsData;
            const [validOrg] = validOrgList() || [];
            if (validOrg && (!activeOrg.value || !integrationsData[activeOrg.value.integId]
                || !integrationsData[validOrg.integId].some(acc => acc.orgId === activeOrg.value.orgId))) {
                activeOrg.value = validOrg;
                $maLocalStorage.activeOrg = validOrg;
            }
        }
    };

    const updateUserOrgs = (orgs) => {
        userOrgs.value = orgs;
        fetched.value.orgs = true;
    };

    const updateUserAccessType = (accessType) => {
        userAccessType.value = accessType;
    };

    const updateGoals = (goalsData) => {
        goals.value = goalsData;
        allGoals.value = Array.from(goalsData);
        fetched.value.goals = true;
    };

    const updateAppCompetitors = (competitors) => {
        appCompetitors.value = competitors;
    };

    const updateAttributions = (attributionsData) => {
        attributions.value = attributionsData;
    };

    const updateCandidateAttr = (candidates) => {
        attrCandidates.value = candidates;
    };

    const changeOrg = (org) => {
        activeOrg.value = org;
    };

    const changeActiveCurrency = (currency) => {
        activeCurrency.value = currency;
    };

    const updateMenuCollapse = (isCollapse) => {
        menuCollapsed.value = isCollapse;
    };

    const fetchApps = async (live = false) => {
        loading.value = true;
        try {
            allOrgs.value = await getUserApps(live);
        } catch (e) {
            console.error('Failed to fetch user apps', e);
        } finally {
            loading.value = false;
        }
    };

    const findOrgCurrency = (orgId) => {
        return orgList.value.find(org => org.orgId === orgId)?.currency;
    };
    const accountIntegrated= computed(() => activeOrg.value !== null);

    watch(activeOrg, (org) => {
        if (org) {
            activeCurrency.value = org.currency;
        }
    });
    return {
        allOrgs,
        loading,
        hasValidOrganization,
        appIdList,
        apps,
        appInfoMapping,
        accountIntegrated,
        appIdOrgIdsMapping,
        orgIdList,
        orgIdMapping,
        orgIdAppMapping,
        appIdOrgMapping,
        orgList,
        fetchApps,
        findOrgCurrency,
        integrations,
        userOrgs,
        goals,
        allGoals,
        ruleTemplates,
        attributions,
        activeOrg,
        accountTotalSpend,
        currencyView,
        attrCandidates,
        activeCurrency,
        menuCollapsed,
        fetched,
        appCompetitors,
        appCompetitorsLoading,
        appInfoFetched,
        userAccessType,
        goalsIdMap,
        appGoalsMap,
        ruleTemplatesComputed,
        attributionsComputed,
        appCompetitorsComputed,
        appCompetitorsLoadingComputed,
        currency,
        currencySymbol,
        attrCandidatesComputed,
        currencies,
        isUserAccessLimited,
        isUserNoAccess,
        accountTotalSpendComputed,
        noValidIntegrations,
        fetchAccountTotalSpend,
        getAppsCompetitors,
        getAttributions,
        getGoals,
        toggleCurrencyView,
        updateIntegrations,
        updateUserOrgs,
        updateUserAccessType,
        updateGoals,
        updateAppCompetitors,
        updateAttributions,
        updateCandidateAttr,
        changeOrg,
        changeActiveCurrency,
        updateMenuCollapse,
    };
});


