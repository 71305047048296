import { defineStore } from 'pinia';
import { PARTNERS } from '@/common/IntegrationUtils';
import { computed, ref } from 'vue';
import { ATTRIBUTED_TIME } from '@/common/DashboardUtils';
import { useMaStorage } from '@/composables';

export const useAttributionStore = defineStore('attribution', ()=> {
    const apps =  ref([]);
    const availableAttributionPartners = ref([]);
    const apiInfo = ref('');
    const attributedTime = useMaStorage('attributedTime', ATTRIBUTED_TIME.INSTALL_TIME, localStorage);

    const availableAttributionPartnerMapping = computed(() => {
        return availableAttributionPartners.value.map(attrPartner => ({
            ...PARTNERS.find(partner => partner.id === attrPartner),
        }));
    });
    const isEventTime = computed(() => attributedTime.value === ATTRIBUTED_TIME.EVENT_TIME);

    const updateAttributedTime = (value) => {
        attributedTime.value = value;
    };

    return {
        apps,
        availableAttributionPartners,
        apiInfo,
        availableAttributionPartnerMapping,
        attributedTime,
        updateAttributedTime,
        isEventTime,
    };
});
